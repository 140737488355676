import {string, object, InferType} from "yup"

export const createUserShema = object({
    email: string().email('Введите корректный email').required('Введите email'),
    login: string().required('Введите логин'),
    fullname: string().required('Введите ФИО'),
    phone: string().required('Введите номер телефона'),
    //regionId: string().required('Установите регион'),
    role: string().required('Установите Роль')
})

export type createUserType = InferType<typeof createUserShema>

