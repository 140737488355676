<template >
    <input-component
        :input-content="content"
        input-type="number"
        :input-name="name"
        :input-label="label"
        :input="edit"
        v-model:meaning="itemValue"
        :disabled="disabled">
    <template #subcontent>
        <radio-input v-model="selectedOption" :options="radioOptions" :radio="edit"/>
    </template>
    </input-component>
</template>
<script setup lang="ts" generic="T extends typeof Calculate['hard' | 'easy']">
import { PropType, onMounted, ref, watch } from 'vue';
import InputComponent from '../../../../shared/Form/Input/InputComponent.vue'
import { RadioInput } from '../../../../shared/Form/Radio';
import { IRadioOption } from '../../../../shared/Form/Radio/lib/type';
import { Calculate, CalculateMethod } from '../../lib/Calculate';
import { PaymentMeasure } from '../../../../app/lib/types/orders';

interface Props {
    name:string
    content:string
    label:string
    disabled:boolean
    input:boolean
    radioOptions:IRadioOption[]
    selectedOption:PaymentMeasure | string
    meaning:number
    price:number
    calculateFunction: T
    calculateArguments?: []
}

const props = defineProps<Props>()

const emit = defineEmits({
    'onChange': null
})

const itemValue = ref(props.meaning)
const item = ref(0)

const selectedOption = ref<PaymentMeasure | string>(props.selectedOption);

const edit = ref(false)
edit.value = props.input
function onCalculate() {
    if (typeof props.calculateFunction === 'function') {
        //@ts-ignore
        item.value = props.calculateFunction({
            final: props.price,
            meaning: item.value,
            meaningValue: itemValue.value,
            option: selectedOption.value,
            ...(props.calculateArguments || [])
        })
    }
}

watch(() => [itemValue.value, selectedOption.value], () => {
    onCalculate()
})
watch(() => [props.input], () => {
    console.log( props.selectedOption)
    edit.value = props.input
    selectedOption.value = props.selectedOption
    itemValue.value = props.meaning
})

watch(() => [itemValue.value, selectedOption.value, item.value], () => {
    emit("onChange", {
        itemValue: itemValue.value,
        item: item.value,
        selectedOption: selectedOption.value,
        label: props.label
    }
    )
})

</script>
<style lang="sass">

</style>