<template >
    <div class="radio-box">
        <span class="radio__text" v-if="radio !== true">{{ radioValue }}</span>
        <div class="radio__list" v-else>
            <label class="radio" v-for="option in options" :key="option.value">
            <input
                type="radio"
                :value="option.value"
                v-model="radioValue"
                @change="handleChange"
            />
            {{ option.label }}
            </label>
        </div>
    </div>
</template>
<script setup lang="ts">
import { PropType, ref, watch } from 'vue';
import { IRadioOption } from '../../lib/type';


const props = defineProps({
    modelValue: {
      type: String,
      required: true
    },
    options: {
      type: Array as PropType<IRadioOption[]>,
      required: true
    },
    radio: {
        type: Boolean,
        default: true
    },
})

const emit = defineEmits({
    'update:modelValue': null,
})

const radioValue = ref('');
radioValue.value = props.modelValue

watch(radioValue, (newValue) => {
    emit('update:modelValue', newValue);
});

const handleChange = (event: any) => {
    radioValue.value = event.target.value;
};

watch(() => [props.modelValue], () => {
    console.log("model");

})


</script>
<style lang="sass">
    .radio
        display: flex
        align-items: center
        font-family: 'Roboto'
        font-weight: 400
        font-size: 18px
        color: var(--gray-dark)
        input
            width: 20px
            height: 20px
            margin-right: 8px
        &__list
            display: flex
            flex-direction: column
            gap: 14px
            width: 100%
            margin-top: 0
        &-box
            width: 100%
        &__text
            display: block
            font-family: 'Roboto'
            font-weight: 400
            font-size: 18px
            color: var(--gray-dark)
</style>