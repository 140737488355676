<template >
    <div class="restore__container">
        <img :src="images.logo" alt="Март" class="restore__logo">
        <h2 class="restore__title">Восстановление пароля</h2>
        <form method="post" class="restore__form">
            <input-component inputType="password" v-model:meaning="restorePassword.password" @input="errors.password = ''" inputName="newPassword" inputContent="********" inputLabel="Введите новый пароль" :inputError="errors.password"/>
            <input-component inputType="password" v-model:meaning="confirmedPassword" inputName="confirmedPassword" inputContent="********" inputLabel="Повторите новый пароль" :inputError="confirmed"/>
            <div class="restore__button-box">
                <button-component buttonText="Восстановить пароль" class="restore__button" :class="{ restore__button_disabled: disabled }" :disabled="disabled"  @click.prevent="resetPassword"/>
            </div>
        </form>
    </div>
</template>
<script setup lang="ts">
import logo from '../assets/img/svg/logo.svg';
import InputComponent from '../shared/Form/Input/InputComponent.vue';
import buttonComponent from '../shared/buttonComponent.vue';
import toast from "../app/lib/hooks/errorToast"
import axios from '../app/lib/api'
import { ref, watch } from "vue"
import router from '../app/router/router';
import { useRoute } from 'vue-router';
import { resetPasswordShema } from '../app/lib/shemas/updateUserShema';

const route = useRoute()

const images = {
    logo: logo
}

const restorePassword = ref({
    resetToken: route.query.resetToken as string,
    password: ""
})

const errors = ref({
    password: ""
})


const confirmedPassword = ref("")
const confirmed = ref("")
const disabled = ref(true)


async function resetPassword() {
    try {
        await resetPasswordShema.validate(restorePassword.value, { abortEarly: false })
        errors.value = {
            password: ""
        }
        try {
            const res = await axios.auth.resetPassword(restorePassword.value)
            toast("success", res.data.message)
            router.push({name: 'login'})
        } catch (error: any) {
            toast("error", error.response.data.message)
            error.response.data.errors?.forEach((item: any) => toast("error", item.message))
        }
    } catch (error: any) {
        // @ts-ignore
        error.inner.forEach((error) => {
            // @ts-ignore
            errors.value[error.path] = error.message;
            if (error.path.includes("resetToken")) {
                toast('error', "Ошибка доступа")
            }
        });
    }
}

watch(() => [confirmedPassword.value, restorePassword.value.password], () => {
    if(confirmedPassword.value === restorePassword.value.password) {
        confirmed.value = ""
        disabled.value = false
    } else {
        confirmed.value = "Пароли должны совпадать"
        disabled.value = true
    }
})

</script>
<style lang="sass">
.restore
    &__container
        max-width: 426px
        width: 100%
        margin: 0 auto
        padding: 0 20px
    &__logo
        width: 230px
        margin: 0 auto
    &__title
        margin-top: 100px
        color: var(--brown)
        font-family: 'Roboto'
        font-weight: 500
        font-size: 24px
        text-align: center
    &__form
        margin-top: 30px
        width: 100%
        display: flex
        flex-direction: column
        gap: 30px
    &__save
        display: none
        &:checked ~ .restore__save-text::before
            background: url(../assets/img/svg/icon/cheked.svg) no-repeat center center
            border-color: var(--gold)
        &-box
            display: flex
            align-items: center
        &-text
            display: block
            color: var(--brown)
            font-family: 'Roboto'
            font-weight: 400
            font-size: 18px
            display: flex
            align-items: center
            &::before
                display: block
                content: ''
                width: 18px
                height: 18px
                border: 2px solid var(--brown)
                border-radius: 3px
                margin-right: 10px
    &__button
        width: 100%
        text-align: center
        padding: 14px 20px
        background-color: var(--gold)
        color: var(--white)
        text-transform: uppercase
        transition: .3s ease-in-out
        transition-property: background-color
        &:hover
            @media (min-width: 540px)
            background-color: var(--brown)
        &_disabled
            pointer-events: none
            background-color: var(--gray)
</style>