<template>
  <div v-if="offlineReady || needRefresh" class="flex flex-wrap" role="alert">
    <div class="message mt-1">
      <span v-if="offlineReady"> Приложение работает в оффлайн режиме </span>
      <span v-else>Нажмите на кнопку чтобы обновить приложение</span>
    </div>
    <div class="buttons flex align-middle mt-2 md:mt-0">
      <button v-if="needRefresh" @click="getupdateServiceWorker()" class="button">
        обновить
      </button>
      <button @click="close" class="button">
        закрыть
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useRegisterSW } from "virtual:pwa-register/vue";

const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW();
const close = async () => {
  offlineReady.value = false;
  needRefresh.value = false;
  console.log("close")
};

async function getupdateServiceWorker() {
  await updateServiceWorker();
  console.log("reload")
}

</script>