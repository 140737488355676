<template>
    <div class="users-list">
        <div class="users-list__title-box">
            <h2 class="users-list__title">Список пользователей</h2>
            <div class="users-list__sort-box">
                <div class="users-list__select-box">
                    <icon-component class="users-list__icon" name="select" width="20" height="20"/>
                    <v-select v-model="selected" :options="sort"  @option:selected="triageUsers" label="title" :searchable="false"/>
                </div>
                <!-- <div class="users-list__sort" v-if="!sorting">
                    <icon-component class="users-list__icon" name="sortUp" width="20" height="20"/>
                    <button class="users-list__sort-button" @click="sortingUsersTarget">по возрастанию</button>
                </div>
                <div class="users-list__sort" v-if="sorting">
                    <icon-component class="users-list__icon" name="sortDown" width="20" height="20"/>
                    <button class="users-list__sort-button" @click="sortingUsersTarget">по убыванию</button>
                </div> -->
                <div class="users-list__select-box">
                    <icon-component class="users-list__icon" name="select" width="20" height="20"/>
                    <v-select v-model="role" :options="roles" @option:selected="filteredRoles" label="title" :searchable="false"/>
                </div>
            </div>
        </div>
        <ul class="users-list__list">
            <li class="users-list__item" v-for="(user, index) in sortUsers" :key="index">
                <div class="users-list__content">
                    <span class="users-list__text users-list__index">№{{ index + 1 }}</span>
                    <span class="users-list__text users-list__name" v-if="user.contacts">{{user.contacts.fullname}}</span>
                    <span class="users-list__text users-list__text_gold users-list__text_min">{{user.login}}</span>
                    <span class="users-list__text users-list__email">{{ user.email }}</span>
                    <v-select 
                        :searchable="false"
                        :autoscroll="false"
                        :modelValue="user.role" 
                        :options="updateRoles" 
                        @option:selected="(role: UserRole) => updadeRole(user._id, role)"
                    />
                </div>
                <div class="users-list__action-box">
                    <VDropdown>
                        <button class="users-list__action">
                            <icon-component class="users-list__icon" name="action" width="32" height="32"/>
                        </button>
                        <template #popper>
                            <div class="users-list__option-box">
                                <button class="users-list__option" @click="deleteUser(user._id)">Удалить пользователя</button>
                                <button class="users-list__option">Информация о Пользователе</button>
                            </div>
                        </template>
                    </VDropdown>
                </div>
            </li>
        </ul>  
    </div>
</template>
<script setup lang="ts">
import iconComponent from './iconComponent.vue';
import axios from '../app/lib/api'
import { User, UserRole } from '../app/lib/types/users';
import { ref, onMounted, computed } from 'vue';
import { sortBy } from 'lodash';
import router from '../app/router/router';
import toast from '../app/lib/hooks/errorToast';
import {useLoadingStore} from '../app/store/store';

const sort = [
    "по ФИО",
    "по логину",
    "по Email",
    "по дате создания",
]
const selected = ref("Сортировка")

const roles = [
    "Администратор",
    "Главный менеджер",
    "Менеджер",
    "Исполнитель",
    "Все"
]
const loading = useLoadingStore()
const role = ref("Роль")
const updateRoles = Object.values(UserRole)
const userRole = localStorage.getItem("role")

const users = ref([] as unknown as User[])
const sortUsers = ref([] as unknown as User[])


function updadeRole(id: string, role: UserRole) {
    loading.loading()
    axios.users.updateUserRole(id, role)
    .then(() => {
        const user = sortUsers.value.find(user => user._id === id )
        if(user){
            user.role = role
        }
        loading.load()
    })
    .catch(() => {
        loading.load()
        toast("error", "Не удалось изменить роль")
    })
}

function deleteUser(id: string,) {
    loading.loading()
    axios.users.deleteUser(id)
    .then(() => {
        toast("success", "Пользователь успешно удален")
        sortUsers.value = sortUsers.value.filter(user => user._id !== id)
        loading.load()
    })
    .catch((err: any) => {
        loading.load()
        toast("error", "Не удалось удалить пользователя")
    })
}

const filterRoleUsers = ref([] as unknown as User[])
const filteredRoles = computed(() => {
    const filteredRoles = users.value
    switch (role.value) {
        case "Исполнитель":
            filterRoleUsers.value = filteredRoles
            filterRoleUsers.value = filteredRoles.filter(item => item.role === "Исполнитель")
            sortUsers.value = filterRoleUsers.value
        break;
        case "Главный менеджер":
            filterRoleUsers.value = filteredRoles
            filterRoleUsers.value = filteredRoles.filter(item => item.role === "Главный менеджер")
            sortUsers.value = filterRoleUsers.value
        break;
        case "Менеджер":
            filterRoleUsers.value = filteredRoles
            filterRoleUsers.value = filteredRoles.filter(item => item.role === "Менеджер")
            sortUsers.value = filterRoleUsers.value
        break;
        case "Администратор":
            filterRoleUsers.value = filteredRoles
            filterRoleUsers.value = filteredRoles.filter(item => item.role === "Администратор")
            sortUsers.value = filterRoleUsers.value
        break;
        default:
            filterRoleUsers.value = filteredRoles
            sortUsers.value = filterRoleUsers.value
    }
    triageUsers
})

const sortingUsers = ref([] as unknown as User[])
const triageUsers = computed(() => {
    const triageUsers = [...filterRoleUsers.value]
    const cleanUser = filterRoleUsers.value
    switch (selected.value) {
        case "по ФИО":
            sortingUsers.value = cleanUser
            sortingUsers.value = sortBy(triageUsers, "contacts.fullname")
            sortUsers.value = sortingUsers.value
        break;
        case "по логину":
            sortingUsers.value = cleanUser
            sortingUsers.value = sortBy(triageUsers, item => item.login.toLowerCase())
            sortUsers.value = sortingUsers.value
        break;
        case "по Email":
            sortingUsers.value = cleanUser
            sortingUsers.value = sortBy(triageUsers, item => item.email.toLowerCase())
            sortUsers.value = sortingUsers.value
        break;
        case "по дате создания":
            sortingUsers.value = cleanUser
            sortingUsers.value = sortBy(triageUsers, item => item.createdAt)
            sortUsers.value = sortingUsers.value
        break;
        default:
            sortingUsers.value = cleanUser
            sortUsers.value = sortingUsers.value
    }
})

onMounted(() => {
    loading.loading()
    axios.users.getUsersList()
    .then((res: any) => {
        users.value  = res.data as User[]
        filterRoleUsers.value = users.value
        sortUsers.value = filterRoleUsers.value
        loading.load()
    })
    .catch((err: any) => {
        loading.load()
    })


})

if(userRole !== UserRole.ADMIN) {
    router.push({name: 'main'})
}

// const sorting = ref(false)
// const sortingUsersTarget = () => {
//     sorting.value = !sorting.value
// }
</script>
<style lang="sass">
    .users-list
        max-width: 1100px
        width: 100%
        margin-top: 60px
        &__title
            display: block
            font-family: 'Roboto'
            font-weight: 500
            font-size: 32px
            color: var(--brown)
            &-box
                width: 100%
                display: flex
                align-items: center
                gap: 30px
                @media (max-width: 1450px)
                    display: block

        &__link
            display: block
            font-family: 'Roboto'
            font-weight: 400
            font-size: 20px
            color: var(--gold)
            text-decoration: underline
        &__sort
            display: flex
            align-items: center
            gap: 10px
            &-box
                display: flex
                align-items: center
                gap: 40px
                @media (max-width: 1450px)
                    margin-top: 30px
                    flex-wrap: wrap
            &-button
                display: block
                background: none
                border: none
                font-family: 'Roboto'
                font-weight: 400
                font-size: 16px
                color: var(--brown)
        &__icon
            display: block
            color: var(--brown)
        &__select
            &-box
                display: flex
                align-items: center
                gap: 10px
        .vs__dropdown-menu
            width: 200px
        .vs__dropdown-toggle
            width: 200px
            height: 30px
            border: none
        .vs__search
            //display: none
        .vs__actions
            flex: none
        .vs__clear
            display: none
        &__list
            display: block
            margin-top: 30px
            width: 100%
            max-width: 1010px
        &__item
            width: 100%
            display: flex
            align-items: center
            padding: 24px 30px
            border-bottom: 1px solid var(--gray-light)
            &:hover
                @media (min-width: 540px)
                    background-color: var(--gold-light)
            .vs__dropdown-toggle
                width: 160px
        &__content
            display: flex
            align-items: center
            flex-wrap: wrap
            gap: 20px
        &__text
            font-family: 'Roboto'
            font-weight: 400
            font-size: 18px
            color: var(--brown)
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
            &_gold
                color: var(--gold)
            &_min
                width: 140px
        &__action
            border: none
            background: none
            &-box
                margin-left: auto
                flex: none
        &__option
            display: block
            border: none
            background: none
            font-family: 'Roboto'
            font-weight: 600
            font-size: 14px
            color: var(--brown)
            &:not(:first-child)
                margin-top: 20px
            &-box
                width: 200px
                padding: 20px
                border-radius: 3px
        &__name
            width: 280px
        &__email
            width: 200px
        &__index
            width: 40px

</style>