import { defineStore } from "pinia"
import { ref, computed, watch } from "vue"
import { OrderCosts, PaymentMeasure } from "../../../../../app/lib/types/orders"
import { Calculate } from "../../../../../features/CalculationBlock/lib/Calculate"
import CostTemplateVue from "../../../../../shared/CostCalculationTemplate/CostTemplate.vue"
export interface ICost {
    itemValue: number
    item: number
    selectedOption: string
    label: string
}

export const useCostsStore = defineStore('costs', ()=> {
    const staticCost: OrderCosts = {
        concrete: 0,
        concreteValue: 0,
        concreteMeasure: PaymentMeasure.currency,
        materials: 0,
        materialsValue: 0,
        materialsMeasure: PaymentMeasure.currency,
        departure: 0,
        departureValue: 0,
        departureMeasure: PaymentMeasure.currency,
        brigade: 0,
        brigadeValue: 0,
        brigadeMeasure: PaymentMeasure.currency,
        raw: 0,
        rawValue: 0,
        rawMeasure: PaymentMeasure.percent,
        install: 0,
        installValue: 0,
        installMeasure: PaymentMeasure.percent,
    }
    const costs = ref<OrderCosts>(staticCost)

    let price = 0

    function getCosts(data: OrderCosts, dataPrice: number) {
        costs.value = staticCost
        costs.value = data
        price = dataPrice
    }
    function setCost (data: ICost) {
        console.log(data.label)
        switch(data.label) {
            case 'ЦПА':
                costs.value.raw = data.item
                costs.value.rawValue = data.itemValue
                costs.value.rawMeasure = data.selectedOption
                break
            case 'Расходники':
                costs.value.materials = data.item
                costs.value.materialsValue = data.itemValue
                costs.value.materialsMeasure = data.selectedOption
                break
            case 'МАП':
                costs.value.concrete = data.item
                costs.value.concreteValue = data.itemValue
                costs.value.concreteMeasure = data.selectedOption
                break
            case 'Установщики':
                costs.value.install = data.item
                costs.value.installValue = data.itemValue
                costs.value.installMeasure = data.selectedOption
                break
            case 'Бригада':
                costs.value.brigade = data.item
                costs.value.brigadeValue = data.itemValue
                costs.value.brigadeMeasure = data.selectedOption
                break
            case 'Выезд':
                costs.value.departure = data.item
                costs.value.departureValue = data.itemValue
                costs.value.departureMeasure = data.selectedOption
                break
        }
    }

    //TODO убрать консоль лог
    watch(() => [costs.value.raw, costs.value.materials], () => {
        console.log("отработала")
        costs.value.concrete = Calculate.hard({
            costs: costs.value,
            final: price,
            meaning: costs.value.concrete,
            meaningValue: costs.value.concreteValue,
            option: costs.value.concreteMeasure,
        })
        costs.value.install = Calculate.hard({
            costs: costs.value,
            final: price,
            meaning: costs.value.install,
            meaningValue: costs.value.installValue,
            option: costs.value.installMeasure,
        })
    })

    function $reset() {
        costs.value = {...staticCost}
        price = 0
    }


    return { costs, getCosts, setCost, $reset}
})