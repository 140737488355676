/**
 * Обработчик запросов к бэкенду.
 * 
 * @param instance 
 * @returns 
 */
export default function(instance: any) {
    return {
        getCitiesList() {
            return instance.get('cities');
        },
        getCityById(cityId: string) {
            return instance.get(`cities/${cityId}`);
        },
        getCurrentCity() {
            return instance.get('cities/currentCity');
        }
    }
}

export {}