import { POSITION } from 'vue-toastification';
import { useToast } from 'vue-toastification';

export default function toast (status:status, message: string) {
    const toast = useToast()
    const params = {
        position: POSITION.BOTTOM_RIGHT,
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
    }
    switch(status) {
        case "error": toast.error(message, params); break
        case "warning": toast.warning(message, params); break
        default: return toast.success(message, params)
    }
}

type status = "error" | "success" | "warning"