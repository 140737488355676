<template>
    <div class="order-list" :class="{mt_80: props.margin}">
        <div class="order-list__title-box">
            <h2 class="order-list__title">Архив</h2>
            <router-link to="/orders" class="order-list__link" v-if="props.link">смотреть все</router-link>
            <div class="order-list__sort-box" v-if="props.sorting">
                <div class="order-list__sort" v-if="!upOrders">
                    <icon-component class="order-list__icon" name="sortUp" width="20" height="20"/>
                    <button class="order-list__sort-button" @click.prevent="sortingOrders">по возрастанию</button>
                </div>
                <div class="order-list__sort" v-else>
                    <icon-component class="order-list__icon" name="sortDown" width="20" height="20"/>
                    <button class="order-list__sort-button" @click.prevent="sortingOrders">по убыванию</button>
                </div>
            </div>
        </div>
        <ul class="order-list__list">
            <li class="order-list__item" v-for="(order, index) in quantityOrders" :key="index">
                <div class="order-list__content">
                    <span class="order-list__text order-list__index">№{{ index + 1 }}</span>
                    <span class="order-list__text order-list__name">{{ order.information.client }}</span>
                    <span class="order-list__text order-list__text_gray order-list__type">{{order.type}}</span>
                    <span class="order-list__text order-list__id">{{ order.number }}</span>
                    <span class="order-list__text order-list__status">{{ order.status }}</span>
                </div>
                <div class="order-list__action-box">
                    <VDropdown>
                        <button class="order-list__action">
                            <icon-component class="order-list__icon" name="action" width="32" height="32"/>
                        </button>
                        <template #popper>
                          <div class="order-list__option-box">
                            <router-link :to="`/order/${order._id}`" class="order-list__option">Подробнее</router-link>
                          </div>
                        </template>
                    </VDropdown>
                </div>
            </li>
        </ul>
    </div>
</template>
<script setup lang="ts">
import axios from '../../app/lib/api';
import { Order, OrderStatus } from '../../app/lib/types/orders';
import iconComponent from '../../shared/iconComponent.vue';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { onMounted, ref, watch } from 'vue';
import toast from '../../app/lib/hooks/errorToast';
import {useLoadingStore} from '../../app/store/store';
dayjs.extend(isBetween)

const props = defineProps({
    margin: {
        type: Boolean
    },
    link: {
        type: Boolean
    },
    sorting: {
        type: Boolean
    },
    date: {
        type: Array
    },
    filtered: {
        type: Boolean
    },
    quantity: {
        type: Number
    }
})

const loading = useLoadingStore()
const orders = ref([] as unknown as Order[])
const sortOrders = ref([] as unknown as Order[])
const filteredOrders = ref([] as unknown as Order[])
const upOrders = ref(true)
const quantityOrders = ref([] as unknown as Order[])

function setQuantityOrders() {
    if (props.quantity) {
        quantityOrders.value = filteredOrders.value.slice(0, props.quantity)
    } else {
        quantityOrders.value = filteredOrders.value
    }
}

const sortingOrders = () => {
    upOrders.value = !upOrders.value
    if(upOrders) {
        sortOrders.value.reverse()
    } else {
        sortOrders.value = orders.value
    }
}

watch(() => [props.date, upOrders], () => {
    if(props.filtered == true ) {
        //@ts-ignore
        filteredOrders.value = sortOrders.value.filter( item => dayjs(item.createdAt).isBetween(props.date[0], props.date[[props.date.length - 1]], 'day', []))
    } else {
        filteredOrders.value = sortOrders.value
    }
    setQuantityOrders()
},
{ deep: true })

onMounted(() => {
    loading.loading()
    let filter = {
        useTableMap: false,
        perPage: 0,
        sortBy: 'createdAt',
        sortOrder: 'desc',
        pageOffset: 0,
    }
    axios.order.getOrderList(filter)
    .then((res: any) => {
        orders.value = res.data.result.filter((order: Order) => order.status == OrderStatus.archive)
        sortOrders.value = orders.value
        sortingOrders()
        filteredOrders.value = sortOrders.value
        setQuantityOrders()
        loading.load()
    })
    .catch((err: any) => {
        loading.load()
        toast('error', "Не удалось загрузить заказы")
    })
})

</script>
<style lang="sass">
    .mt_80
        margin-top: 80px
    .v-popper--theme-dropdown
        .v-popper__inner
            border: none
            border-radius: 0
    .payments
        display: block
        &-box
            margin-top: 30px
        &__information
            display: grid
            grid-template-columns: repeat(5, 1fr)
            gap: 20px
            @media (max-width: 915px)
                grid-template-columns: repeat(3, 1fr)
            @media (max-width: 600px)
                grid-template-columns: repeat(2, 1fr)
            @media (max-width: 440px)
                grid-template-columns: 100%
            &-item
                display: flex
                flex-direction: column
                align-items: center
                gap: 10px
        &__title
            font-family: 'Roboto'
            font-weight: 600
            font-size: 18px
            color: var(--brown)
            display: block
            &_gold
                color: var(--gold)
        &__item
            margin-top: 20px
            display: grid
            grid-template-columns: repeat(4, 200px)
            gap: 30px
            padding-bottom: 10px
            border-bottom: 1px solid var(--gray)
            @media (max-width: 1020px)
                grid-template-columns: repeat(2, 200px)
            @media (max-width: 560px)
                grid-template-columns: 280px
        &__text
            display: block
            font-family: 'Roboto'
            font-weight: 400
            font-size: 18px
            color: var(--brown)
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
            padding: 10px
            &_gray
                color: var(--gray)
            &_np
                padding: 0
    .order-list
        max-width: 1200px
        width: 100%
        &__title
            display: block
            font-family: 'Roboto'
            font-weight: 500
            font-size: 32px
            color: var(--brown)
            &-box
                width: 100%
                display: flex
                align-items: center
                flex-wrap: wrap
                gap: 30px
        &__link
            display: block
            font-family: 'Roboto'
            font-weight: 400
            font-size: 20px
            color: var(--gold)
            text-decoration: underline
        &__sort
            display: flex
            align-items: center
            flex-wrap: wrap
            gap: 10px
            &-box
                display: flex
                align-items: center
                gap: 40px
                @media (max-width: 500px)
                    flex-wrap: wrap
            &-button
                display: block
                background: none
                border: none
                font-family: 'Roboto'
                font-weight: 400
                font-size: 16px
                color: var(--brown)
        &__icon
            display: block
            color: var(--brown)
        &__list
            display: block
            margin-top: 30px
            width: 100%
        &__item
            width: 100%
            display: flex
            align-items: center
            padding: 24px 30px
            border-bottom: 1px solid var(--gray-light)
            &:hover
                @media (min-width: 540px)
                    background-color: var(--gold-light)
        &__content
            display: flex
            align-items: center
            flex-wrap: wrap
            .vs__dropdown-toggle
                width: 150px
                height: 30px
                border: none
            .vs__search
                //display: none
            .vs__actions
                flex: none
            .vs__clear
                display: none
        &__text
            font-family: 'Roboto'
            font-weight: 400
            font-size: 18px
            color: var(--brown)
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
            &_gray
                color: var(--gray)
        &__action
            border: none
            background: none
            &-box
                margin-left: auto
        &__option
            display: block
            border: none
            background: none
            font-family: 'Roboto'
            font-weight: 600
            font-size: 14px
            color: var(--brown)
            &:not(:first-child)
                margin-top: 20px
            &-box
                width: 200px
                padding: 20px
                border-radius: 3px
        &__name
            width: 280px
        &__index
            width: 160px
        &__type
            width: 217px
        &__id
            width: 120px
        &__status
            width: 140px
</style>