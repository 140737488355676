import { defineStore } from "pinia"
import { ref, computed } from "vue"
import { ICreateOrderLocal } from "../lib/shemas/createOrderShema"
import { createOrderType } from "../lib/shemas/createOrderShema"

export const useLoadingStore = defineStore('loading', () => {
    const preloader = ref(true)

    function loading() {
        preloader.value = true
    }

    function load() {
        preloader.value = false
    }

    return { preloader, load, loading }
  })

export const useOnlineStore = defineStore('online', () => {
    const isOnline = ref(true)

    const getOnline = computed(() => {
        isOnline.value  = true
    })

    const getOffline = computed(() => {
        isOnline.value  = false
    })

    return { isOnline, getOnline, getOffline }
  })


export const useOdrderLocalStore = defineStore('orderLocal', () => {
    const orders = ref<ICreateOrderLocal[]>([])

    const getOrders = (() => {
        if(localStorage.getItem('orders')) {
            orders.value = JSON.parse(localStorage.getItem('orders') || '[]')
        } else {
            return orders
        }
    })

    const setOrders = (() => {
        localStorage.setItem('orders', JSON.stringify(orders.value))
    })

    function saveOrder(order: createOrderType) {
        getOrders()
        let ID = orders.value.length === 0 ? 1 : (+(orders.value[orders.value.length - 1].localID) + 1)
        let localID = ID.toString()
        let localOrder: ICreateOrderLocal = {localID, ...order}
        orders.value.push(localOrder)
        setOrders()
    }

    function deleteOrder(id: string) {
        getOrders()
        orders.value = orders.value.filter(item => item.localID !== id)
        setOrders()
    }

    return { orders, getOrders, saveOrder, deleteOrder }
  })