<template >
    <div class="restore__container">
        <img :src="images.logo" alt="Март" class="restore__logo">
        <div class="restore__form-box" v-if="!restore">
            <h2 class="restore__title">Восстановление пароля</h2>
            <form method="post" class="restore__form">
                <input-component v-model:meaning="valume.identity" @input="errors.identity = ''" inputType="text" inputName="email" inputContent="admin" inputLabel="Логин или e-mail" :inputError="errors.identity"/>
                <div class="restore__button-box">
                    <button-component buttonText="Восстановить пароль" class="restore__button" @click.prevent="forgotPassword"/>
                    <router-link :to="{name:'login'}" class="restore__link">Назад</router-link>
                </div>
            </form>
        </div>
        <div class="restore__text-box" v-else>
            <p class="restore__text">Заявка на восстановление пароля отправлена!Проверьте электронную почту <b>{{valume.identity}}</b></p>
            <router-link :to="{name:'login'}" class="restore__link restore__link_back">Вернуться к странице входа</router-link>
        </div>
    </div>
</template>
<script setup lang="ts">
import logo from '../assets/img/svg/logo.svg';
import InputComponent from '../shared/Form/Input/InputComponent.vue';
import buttonComponent from '../shared/buttonComponent.vue';
import toast from "../app/lib/hooks/errorToast"
import axios from '../app/lib/api'
import { ref } from "vue"
import router from '../app/router/router';
import { forgotPasswordShema } from '../app/lib/shemas/updateUserShema';


const images = {
    logo: logo
}

const valume= ref({
    identity: ""
})
const errors =ref({
    identity: ""
})

async function forgotPassword() {
    try {
        await forgotPasswordShema.validate(valume.value, { abortEarly: false })
        errors.value = {
            identity: ""
        }
        try {
            const res = await axios.auth.forgotPassword(valume.value)
            toast("success", res.data.message)
            restore.value = true
        } catch (error: any) {
            toast("error", error.response.data.message)
            error.response.data.errors?.forEach((item: any) => toast("error", item.message))
        }
    } catch (error: any) {
        // @ts-ignore
        error.inner.forEach((error) => {
            // @ts-ignore
            errors.value[error.path] = error.message;
        });
    }
}

const restore = ref(false)

</script>
<style lang="sass">
.restore
    &__container
        max-width: 426px
        width: 100%
        margin: 0 auto
        padding: 0 20px
    &__logo
        width: 230px
        margin: 0 auto
    &__title
        margin-top: 100px
        color: var(--brown)
        font-family: 'Roboto'
        font-weight: 500
        font-size: 24px
        text-align: center
    &__form
        margin-top: 30px
        width: 100%
        display: flex
        flex-direction: column
        gap: 30px
    &__save
        display: none
        &:checked ~ .restore__save-text::before
            background: url(../assets/img/svg/icon/cheked.svg) no-repeat center center
            border-color: var(--gold)
        &-box
            display: flex
            align-items: center
        &-text
            display: block
            color: var(--brown)
            font-family: 'Roboto'
            font-weight: 400
            font-size: 18px
            display: flex
            align-items: center
            &::before
                display: block
                content: ''
                width: 18px
                height: 18px
                border: 2px solid var(--brown)
                border-radius: 3px
                margin-right: 10px
    &__button
        width: 100%
        text-align: center
        padding: 14px 20px
        background-color: var(--gold)
        color: var(--white)
        text-transform: uppercase
    &__link
        display: block
        margin-top: 15px
        width: 100%
        text-align: center
        font-family: 'Roboto'
        font-weight: 400
        font-size: 18px
        padding: 14px 20px
        border: 1px solid var(--gray)
        border-radius: 3px
        color: var(--gray)
        &_back
            background-color: var(--gold)
            color: var(--white)
    &__text
        font-family: 'Roboto'
        font-weight: 300
        font-size: 20px
        text-align: center
        color: var(--brown)
        max-width: 594px
        width: 100%
        b
            font-weight: 500
        &-box
            margin-top: 100px
</style>