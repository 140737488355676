<template>
    <transition>
        <div @click="close" class="popup__backdrop" v-if="isOpen">
            <div class="popup" @click.stop>
                <button @click.stop.prevent="close" class="popup__close">
                    <icon-component name="close" width="32" height="32" class="popup__close-icon"/>
                </button>
                <div class="popup__header">
                    <h3 class="popup__title">
                        <slot name="title" ></slot>
                    </h3>
                    <slot name="additional_title_info"></slot>
                </div>
                <div :class="{popup__content: mt}">
                    <slot name="content" :close="close" :confirm="confirm">
                    </slot>
                </div>
            </div>
        </div>
    </transition>
</template>
<script setup lang="ts">
import iconComponent from '../shared/iconComponent.vue';
const props = defineProps({
    isOpen: {
        type: Boolean,
        required: true
    },
    mt: {
        type: Boolean
    }
})
const emit = defineEmits({
    close: null,
    confirm: null
})
const close = () => {
    emit("close")
}
const confirm = () => {
    emit("confirm")
}


</script>
<style lang="sass">
    .popup
        padding: 40px
        background-color: var(--white)
        position: relative
        margin: auto
        cursor: default
        &__backdrop
            position: fixed
            top: 0
            right: 0
            bottom: 0
            left: 0
            z-index: 100000
            background-color: rgba(105, 100, 94, 0.2)
            display: flex
            padding: 20px
            cursor: pointer
            overflow: auto
        &__header
            display: flex
            justify-content: space-between
        &__title
            font-family: 'Roboto'
            font-weight: 500
            font-size: 24px
            color: var(--brown)
            max-width: 260px
            width: 100%
        &__content
            margin-top: 30px
        &__close
            position: absolute
            top: 10px
            right: 10px
            border: none
            background: none
            cursor: pointer
            &-icon
                display: block
                color: var(--brown)
    .v-enter-active,
    .v-leave-active
        transition: .3s ease-in-out
        transition-property: opacity
        opacity: 1
        pointer-events: auto
    .v-enter-from,
    .v-leave-to
        opacity: 0
        pointer-events: none
</style>