<template>
    <form class="payments-form">
        <input-component v-model:meaning="payment.amount" @input="(event: any)=> maxPaymentInput(+(event.target.value))" inputType="number" inputName="amount" inputContent="100"/>
        <v-select class="payments-form__select" :options="methods" v-model="payment.method" />
        <Datepicker class="payments-form__date" menu-class-name="filter__date"  v-model="payment.date" auto-apply format="dd/MM/yyyy" :enable-time-picker="false" locale="ru">
            <template #calendar-header="{ index, day }">
                <div class="date__day" :class="index === 5 || index === 6 ? 'gold-color' : ''">
                  {{ day }}
                </div>
              </template>
        </Datepicker>
        <button-component class="payments-form__button" buttonText="Добавить" @click.prevent="orderPayment"/>
    </form>
</template>
<script setup lang="ts">
import axios from '../app/lib/api';
import { onBeforeUnmount, ref } from 'vue';
import toast from '../app/lib/hooks/errorToast';
import { orderPaymentShema } from '../app/lib/shemas/updateOrderShema';
import { PaymentMethod } from '../app/lib/types/orders';
import buttonComponent from './buttonComponent.vue';
import InputComponent from './Form/Input/InputComponent.vue';
import {useLoadingStore} from '../app/store/store';

const props = defineProps({
    id: {
        type: String,
        required: true
    },
    remains: {
        type: Number,
        required: true
    }
})

const emit = defineEmits({
    close: null,
    newPayment: null
})

const loading = useLoadingStore()
const payment = ref({
    amount: 0,
    method: "",
    date: new Date(),
})

const close = () => {
    emit("close")
}


function maxPaymentInput(value: number) {
    console.log(value)
    if(value > props.remains) {
        payment.value.amount = props.remains
    } else {
        payment.value.amount = value
    }
}

async function orderPayment() {
    try {
        await orderPaymentShema.validate(payment.value, { abortEarly: false })
        try {
            loading.loading()
            const res = await axios.order.updateOrderPayment(props.id, payment.value)
            loading.load()
            toast("success", "Оплата успешно добавлена")
            emit("newPayment", {
                payment: payment.value,
                id: props.id
            })
            return close()
        } catch (error: any) {
            loading.load()
            toast("error", error.response.data.message)
        }
    } catch (error: any) {
        error.inner.forEach((error:any) => {
            toast("error", error.message)
        });
    }
}

onBeforeUnmount(() => {
    emit("newPayment", {
    })
})

const methods = Object.values(PaymentMethod)

</script>
<style lang="sass">
    .payments-form
        display: grid
        grid-template-columns: repeat(4, 200px)
        gap: 30px
        align-items: center
        @media (max-width: 1020px)
            grid-template-columns: repeat(2, 200px)
        @media (max-width: 560px)
            grid-template-columns: 100%
        &__select
            .vs__dropdown-toggle
                width: 200px
                height: 50px
                border: none
                padding: 14px 5px
                background-color: var(--gray-light)
                @media (max-width: 560px)
                    width: 100%
            .vs__search
                //display: none
            .vs__actions
                flex: none
            .vs__clear
                display: none
        &__button
            background-color: var(--gold)
            color: var(--white)
            padding: 15px
            font-size: 14px
            transition: .3s ease-in-out
            transition-property: background-color
            &:hover
                @media (min-width: 540px)
                    background-color: var(--brown)
        .dp__range_between
            color: var(--gold)
        .dp__input
            border: none
            height: 50px
    .dp__menu_index
        z-index: 100000
</style>