import { createApp } from 'vue'
import './app/style.sass'
import App from './app/App.vue'
import { register } from './registerServiceWorker'

import Toast, { PluginOptions } from "vue-toastification";
import Datepicker from '@vuepic/vue-datepicker';
import vSelect from 'vue-select'
import router from './app/router/router';
import { createPinia } from 'pinia'
import FloatingVue from 'floating-vue'
import Vue3Signature from "vue3-signature"

const options: PluginOptions = {};


createApp(App)
        .use(createPinia())
        .use(Toast, options)
        .use(FloatingVue)
        .component('Vue3Signature', Vue3Signature)
        .component('Datepicker', Datepicker)
        .component("v-select", vSelect)
        .use(router)
        .mount('#app')

register()
