<template>
    <div class="page-card">
        <div class="order-card__list">
            <order-card-component @click="isOrderTypeOpen" class="order-card_vertical" text="Создать заказ" size="70" name="paperAdd"/>
            <order-card-component class="order-card_vertical" text="Черновики" size="70" name="paperBig"/>
        </div>
        <order-list-short-component :margin= true :link= true :quantity="5" />
        <order-local-list-component :margin= true />
        <popup-component :mt="true" :isOpen="isOrderType" @close="(isOrderType = false)">
            <template #title>
                    Выберите тип заказа
            </template>
            <template #content>
                <div class="order-card__list order-card__list_popup">
                    <router-link class="order-card__link" :to="{ name: 'createOrder', query: { type: 'gravesLandscaping' } }">
                        <order-card-component class="order-card_horizontal" text="Благоустройство могилы" size="120" name="grave"/>
                    </router-link>
                    <order-card-component class="order-card_horizontal" text="Благоустройство могилы" size="120" name="grave"/>
                    <order-card-component class="order-card_horizontal" text="Благоустройство могилы" size="120" name="grave"/>
                </div>
            </template>
        </popup-component>
    </div>
</template>
<script setup lang="ts">
import orderCardComponent from '../shared/orderCardComponent.vue';
import popupComponent from '../entities/popupComponent.vue';
import orderListShortComponent from '../shared/orderListShortComponent.vue';
import orderLocalListComponent from '../shared/orderLocalListComponent.vue';
import { ref, onMounted } from 'vue';
import { OrderType } from '../app/lib/types/orders';

const isOrderType = ref(false)
const isOrderTypeOpen = () => isOrderType.value = true

</script>
<style lang="sass">
 .order-card
    &__list
        display: flex
        gap: 30px
        &_popup
            @media (max-width: 886px)
                flex-direction: column
    &__link
        flex: none
        display: block
    &_vertical
        flex-direction: column
        max-width: 386px
    &_horizontal
        max-width: 386px
        @media (max-width: 1310px)
            flex-direction: column
 .chooseYearPopup
    .popup__title
        max-width: inherit
    &__container
        display: flex
        flex-direction: column
        justify-content: start
        align-items: center
</style>