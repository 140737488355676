import { forgotPasswordType, resetPasswordType, updateEmailType, updatePasswordType } from "../shemas/updateUserShema";

export default function(instance: any) {
    return {
        //TODO Обновление токена, не подключено
        refresh() {
            return instance.get('auth');
        },
        // Аутентификация и авторизация
        login(payload: {identity: string, password: string, regionId: string, currentRegionIndex: string}) {
            return instance.post('auth', payload);
        },
        // Выход из профиля
        logout() {
            return instance.delete('auth');
        },
        // Обновление пароля
        updatePassword(password: updatePasswordType) {
            return instance.patch('auth/password', password);
        },
        // Обновление почты
        updateEmail(email: updateEmailType) {
            return instance.patch('auth/email', email);
        },
        // Восстановление пароля
        forgotPassword(password: forgotPasswordType) {
            return instance.post('auth/forgot-password', password);
        },
        // Сброс пароля и устанока нового
        resetPassword(password: resetPasswordType) {
            return instance.post('auth/reset-password', password);
        },
    }
}