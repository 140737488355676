<template >
    <div page-card>
        <h2 class="create-order__title">Наряд-заказ на благоустройство могилы</h2>
        <span class="create-order__required">* — поля, обязательные для заполнения</span>
        <form action="" type="POST" class="create-order__form">
            <div class="order-information__item information">
                <h3 class="order-information__title_min">Даты заказа</h3>
                <div class="information__list">
                    <div class="information__item">
                        <span class="information__title">Дата заключения договора</span>
                        <Datepicker :disabled="disabled" class="create-order__date-item" auto-apply format="dd/MM/yyyy" v-model="createOrder.createdAt" placeholder="Укажите дату" :enable-time-picker="false" @update:model-value="" locale="ru">
                            <template #calendar-header="{ index, day }">
                                <div class="date__day" :class="index === 5 || index === 6 ? 'gold-color' : ''">
                                    {{ day }}
                                </div>
                            </template>
                        </Datepicker>
                    </div>
                    <div class="information__item">
                        <span class="information__title">Дата закрытия договора</span>
                        <Datepicker :disabled="disabled" class="create-order__date-item" auto-apply format="dd/MM/yyyy" v-model="createOrder.closedAt" placeholder="Укажите дату" :enable-time-picker="false" @update:model-value="" locale="ru">
                            <template #calendar-header="{ index, day }">
                                <div class="date__day" :class="index === 5 || index === 6 ? 'gold-color' : ''">
                                    {{ day }}
                                </div>
                            </template>
                        </Datepicker>
                    </div>
                </div>
            </div>
            <div class="create-order__item information" >
                <h3 class="create-order__title_min">Контактная информация</h3>
                <div class="information__list">
                    <input-component :disabled="disabled" v-model:meaning="createOrder.information.client" @input="errors.client= ''" inputType="text" inputName="customer" inputContent="Иванов Иван Иванович" inputLabel="Ф.И.О. заказчика" :required=true :inputError="errors.client" />
                    <input-component :disabled="disabled" v-model:meaning="createOrder.information.clientPhone" @input="errors.clientPhone= ''" inputType="tel" inputName="phone" inputContent="+7 (999) 999-99-99" inputLabel="Номер телефона" :required=true :inputError="errors.clientPhone" />
                    <input-component :disabled="disabled" v-model:meaning="createOrder.information.clientAddress" inputType="text" inputName="adress" inputContent="г. Великий Новгород, ул. Луговая, 7" inputLabel="Адрес" />
                    <input-component :disabled="disabled" v-model:meaning="createOrder.information.clientEmail" inputType="email" inputName="email" inputContent="email@mail.ru" inputLabel="Email"/>
                    <input-component :disabled="disabled" v-model:meaning="createOrder.information.deceased" @input="errors.deceased= ''" inputType="text" inputName="deceased" inputContent="Петров Пётр Петрович" inputLabel="Ф.И.О. покойного" :required=true :inputError="errors.deceased" />
                    <VDropdown
                    :distance="6"
                    :disabled="disabled || createOrder.information.cemetery != ''"
                    @click="errors.cemetery= ''"
                    >
                    <input-component :disabled="disabled" v-model:meaning="createOrder.information.cemetery" @input="errors.cemetery= ''" inputType="text" inputName="cemetery" inputContent="Укажите/введите кладбище" inputLabel="Место установки" :required=true :inputError="errors.cemetery" />
                        <template #popper>
                            <div class="information__select">
                                <button class="information__select-item" v-for="item in cityData.params.cemetery" @click="setCemetry(item.name)">{{item.name}}</button>
                            </div>
                        </template>
                    </VDropdown>
                    <div class="information__date-box">
                        <span class="information__date-title field__title">Дата смерти <b>*</b></span>
                        <Datepicker :disabled="disabled" class="information__date" placeholder="Укажите дату" auto-apply format="dd/MM/yyyy" v-model="createOrder.information.deathDate" :enable-time-picker="false" locale="ru">
                            <template #calendar-header="{ index, day }">
                                <div class="date__day" :class="index === 5 || index === 6 ? 'gold-color' : ''">
                                  {{ day }}
                                </div>
                              </template>
                        </Datepicker>
                    </div>
                    <div class="information__list_sub">
                        <input-component :disabled="disabled" v-model:meaning="createOrder.information.graveDistrict" @input="errors.graveDistrict= ''" inputType="text" inputName="district" inputContent="квадрат" inputLabel="Квадрат" :required=true :inputError="errors.graveDistrict" />
                        <input-component :disabled="disabled" v-model:meaning="createOrder.information.graveRow" @input="errors.graveRow= ''" inputType="text" inputName="row" inputContent="№ ряда" inputLabel="Ряд" :required=true :inputError="errors.graveRow" />
                        <input-component :disabled="disabled" v-model:meaning="createOrder.information.gravePlace" @input="errors.gravePlace= ''" inputType="text" inputName="place" inputContent="№ места" inputLabel="Место" :required=true :inputError="errors.gravePlace" />
                    </div>
                </div>
            </div>
            <!-- <grave-information
                class="create-order__item"
                :propsErrors="graveError"
                :disabled="disabled"
                @information="setInformation"
            /> -->
            <div class="create-order__item service">
                <h3 class="create-order__title_min">Список оказываемых услуг</h3>
                <div class="service__list">
                    <div class="service__item" v-for="service, index in createOrder.services" :key="service.title">
                        <buttonComponent :disabled="disabled" class="service__delete" buttonText="Удалить услугу" :reverseIcon=true iconSize="20" iconName="delete" @click.prevent="removeOrderService(service.title)" />
                        <span class="service__index">{{index + 1}}</span>
                        <span class="service__title">Наименование услуги</span>
                        <v-select :disabled="disabled" v-model="service.title" placeholder="Ограда" :options="serviceNameList" @option:selected="setMeasurement(service)" />
                        <div class="service__parametrs">
                            <input-component :disabled="disabled" v-model:meaning="service.quantity" @input="service.price =service.cost * service.quantity" inputType="number" inputName="quantity" inputContent="100" inputLabel="Размер"/>
                            <input-component :disabled="disabled" v-model:meaning="service.measurement" inputType="text" inputName="measurement" inputContent="п/м" inputLabel="Ед. изм."/>
                            <input-component :disabled="disabled" v-model:meaning="service.cost" @input="service.price =service.cost * service.quantity" inputType="number" inputName="cost" inputContent="1600" inputLabel="Цена за ед., руб."/>
                            <input-component :disabled="disabled" v-model:meaning="service.price" @input="service.cost =+((service.price / service.quantity).toFixed(2))" inputType="number" inputName="price" inputContent="1600" inputLabel="Сумма, руб."/>
                        </div>
                    </div>
                </div>
                <button-component :disabled="disabled" button-text="+ Добавить услугу" class="create-order__button" @click.prevent="addOrderService"/>
            </div>
            <!-- <grave-service
                class="create-order__item"
                :disabled="disabled" :service-list="listService"
                @service="setService"
                @total="setTotalPayment"
            /> -->
            <div class="create-order__item additional-service">
                <h3 class="create-order__title_min">Список оказываемых услуг</h3>
                <textarea :disabled="disabled" class="additional-service__field" v-model="createOrder.moreServices" placeholder="Описание дополнительных услуг"></textarea>
            </div>
            <div class="create-order__item payment">
                <div class="col">
                    <div class="cost">
                        <h3 class="create-order__title_min">Расчёт стоимости</h3>
                        <div class="cost__list">
                            <div class="col">
                                <div class="payment__value-box">
                                    <span class="payment__label">Сумма заказа</span>
                                    <div class="payment__value">{{createOrder.price.total}}</div>
                                </div>
                            </div>
                            <div class="col" v-if="createOrder.price.total > 0">
                                <div class="payment__value-box">
                                    <field-template title="Тип оплаты" :required="true">
                                        <template #content>
                                            <radio-input
                                            :options="types"
                                            v-model="prepayment.prepaymentType"
                                            />
                                        </template>
                                    </field-template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="discount">
                        <div class="col" v-if="createOrder.price.total > 0">
                            <input-component
                            input-type="number"
                            input-name="discount"
                            input-content="0"
                            input-label="скидка"
                            v-model:meaning=createOrder.price.discountValue @input="(event: any)=> maxDiscountInput(+(event.target.value))
                            ">
                                <template #subcontent>
                                    <radio-input
                                    v-model="createOrder.price.discountMeasure"
                                    :options="measure"
                                    />
                                </template>
                            </input-component>
                        </div>
                        <div class="col" v-if="prepayment.prepaymentType == PrepaymentType.prepayment">
                            <input-component
                            inputType="number"
                            inputName="measure"
                            inputContent="50"
                            input-label="размер аванса"
                            v-model:meaning=prepayment.prepaymentValue @input="(event: any)=> maxPrepaymentInput(+(event.target.value))"
                            >
                                <template #subcontent>
                                    <radio-input
                                    v-model="prepayment.prepaymentMeasure"
                                    :options="measure"
                                    />
                                </template>
                            </input-component>
                        </div>
                    </div>
                    <div class="create-order__item pay">
                        <div class="col" v-if="createOrder.prepayment">
                            <div class="payment__value-box">
                                <span class="payment__label payment__label_big">К оплате</span>
                                <div class="payment__value payment__value_gold">{{createOrder.prepayment.amount}}</div>
                            </div>
                            <div class="payment__value-box payment__value_mt">
                                <field-template title="Тип оплаты" :required="true">
                                    <template #content>
                                        <radio-input
                                        :options="methods"
                                        v-model="createOrder.prepayment.method"
                                        />
                                    </template>
                                </field-template>
                            </div>
                        </div>
                        <!-- <div class="col">
                            <div class="payment__value-box">
                                <span class="payment__label">остаток</span>
                                <div class="payment__value">~34 000 руб.</div>
                            </div>
                        </div> -->
                    </div>
                    <button-component class="create-order__submit" @click.prevent="openSigntaure = true" v-if="!image && createOrder.price.total > 0" button-text="Создать заказ"/>
                    <button-component class="create-order__submit" @click.prevent="submit" v-if="image && onlineStore.isOnline === true" button-text="Подтвердить заказ"/>
                    <button-component class="create-order__submit" @click.prevent="submit" v-if="image && onlineStore.isOnline === false" button-text="Сохранить заказ"/>
                    <button-component class="create-order__remove" @click.prevent="image = ''" v-if="image" button-text="Вернуться к редактированию заказа"/>
                </div>
                <div class="col">
                    <h3 class="create-order__title_min">Сроки выполнения работ</h3>
                    <div class="create-order__date">
                        <div class="col">
                            <span class="payment__label">Начало работ <b>*</b></span>
                            <Datepicker :disabled="disabled" class="create-order__date-item" placeholder="Укажите дату" auto-apply format="dd/MM/yyyy" v-model="start" :enable-time-picker="false" locale="ru">
                                <template #calendar-header="{ index, day }">
                                    <div class="date__day" :class="index === 5 || index === 6 ? 'gold-color' : ''">
                                      {{ day }}
                                    </div>
                                  </template>
                            </Datepicker>
                        </div>
                        <div class="col">
                            <span class="payment__label">Окончание работ <b>*</b></span>
                            <Datepicker :disabled="disabled" class="create-order__date-item" auto-apply format="dd/MM/yyyy" v-model="end" placeholder="Укажите дату" :enable-time-picker="false" locale="ru">
                                <template #calendar-header="{ index, day }">
                                    <div class="date__day" :class="index === 5 || index === 6 ? 'gold-color' : ''">
                                      {{ day }}
                                    </div>
                                  </template>
                            </Datepicker>
                        </div>
                    </div>
                    <div class="create-order__item create-order__comment">
                        <div class="create-order__item additional-service">
                            <h3 class="create-order__title_min">Комментарий к заказу</h3>
                            <textarea :disabled="disabled" class="additional-service__field" v-model="createOrder.comment" placeholder="Текст комментария"></textarea>
                        </div>
                        <div class="create-order__file-box">
                            <label class="create-order__file">
                                <input type="file" class="create-order__file-input" @change="getFile" accept="image/*">
                                <span class="create-order__file-button">загрузить файл</span>
                            </label>
                            <span class="create-order__file-text">{{fileName}}</span>
                        </div>
                    </div>
                    <div class="create-order__item create-order__signature" v-if="image">
                        <h3 class="create-order__title_min">Подпись заказчика</h3>
                        <img :src="image" alt="" width="280" height="300">
                    </div>
                </div>
            </div>
            <popup-component :is-open="openSigntaure" :mt="false" @close="(closeSignature)" >
                <template #content>
                    <div class="signature-box">
                        <Vue3Signature ref="signature" :w="'280px'" :h="'300px'"></Vue3Signature>
                        <button @click.stop.prevent="clearSignature" class="signature__undo-box">
                            <icon-component width="24" height="24" name="undo" class="signature__undo"/>
                        </button>
                    </div>
                    <div class="signature__button-box">
                        <button-component @click.prevent="createSignature" class="signature__button" button-text="ок"/>
                        <button-component @click.prevent="closeSignature" class="signature__button signature__button_gray" button-text="отмена"/>
                    </div>
                </template>
            </popup-component>
        </form>
    </div>
</template>
<script setup lang="ts">
import InputComponent from '../shared/Form/Input/InputComponent.vue'
import buttonComponent from '../shared/buttonComponent.vue'
import popupComponent from '../entities/popupComponent.vue'
import FieldTemplate from '../shared/Field/FieldTemplate.vue'
import { RadioInput } from '../shared/Form/Radio'
import Vue3Signature from 'vue3-signature'
import iconComponent from '../shared/iconComponent.vue'

import listService from '../app/lib/json/serviceList.json'
import toast from "../app/lib/hooks/errorToast"
import axios from '../app/lib/api'
import {createOrderShema, createOrderType} from '../app/lib/shemas/createOrderShema'
import { OrderService, PaymentMeasure, PrepaymentType, PaymentMethod, OrderType } from '../app/lib/types/orders'
import { computed, onMounted, ref, watch, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import router from '../app/router/router'
import { useLoadingStore, useOnlineStore, useOdrderLocalStore } from '../app/store/store'
import { IGraveInformationErrors, IOrderInformation } from '../entities/GraveInformation/lib/type'
import { IService } from '../features/serviceCard.vue/lib/type'
import { Cities } from '../app/lib/types/cities'

const route = useRoute()
const loading = useLoadingStore()
const onlineStore = useOnlineStore()
const orderLocal = useOdrderLocalStore()
const totalPayment = ref(0)
const cityData = ref({} as Cities)

const createOrder = ref<createOrderType>({
    type: route.query.orderType as OrderType || OrderType.gravesLandscaping,
    information: {
        client: "",
        clientEmail: "",
        clientPhone: "",
        clientAddress: "",
        deceased: "" ,
        deathDate: '',
        cemetery: "" ,
        graveDistrict: "" ,
        graveRow: "" ,
        gravePlace: "" ,
    },
    services: [],
    moreServices: "",
    price: {
        total: 0,
        discountValue: 0,
        discountMeasure: PaymentMeasure.percent,
        discount: 0,
        final: 0,
    },
    prepayment: {
        date: new Date(),
        amount: 0,
        method: "",
    },
    dates: {
      startAt: new Date(),
      endAt: new Date(),
    },
    comment: "",
    uploadImage: "",
    signImage: "",
    costs: {
        concrete: 0,
        concreteValue: 20,
        concreteMeasure: PaymentMeasure.percent,
        materials: 0,
        materialsValue: 0,
        materialsMeasure: PaymentMeasure.percent,
        departure: 0,
        departureValue: 0,
        departureMeasure: PaymentMeasure.percent,
        brigade: 0,
        brigadeValue: 0,
        brigadeMeasure: PaymentMeasure.percent,
        raw: 0,
        rawValue: 12.5,
        rawMeasure: PaymentMeasure.percent,
        install: 0,
        installValue: 20,
        installMeasure: PaymentMeasure.percent,
    },
    createdAt: new Date(),
    closedAt: null
})
const errors = ref({
    client: "",
    clientPhone: "",
    deceased: "",
    deathDate: "",
    cemetery: "",
    graveDistrict: "",
    graveRow: "",
    gravePlace: "",
    method: "",
    startAt: "",
    endAt: "",
})

const graveError = ref<IGraveInformationErrors>({
    client: "",
    clientPhone: "",
    deceased: "",
    deathDate: "",
    cemetery: "",
    graveDistrict: "",
    graveRow: "",
    gravePlace: "",
})

const prepayment = ref({
    prepaymentType: PrepaymentType.withoutPrepayment,
    prepaymentValue: 0,
    prepaymentMeasure: PaymentMeasure.percent,
})
const addServiceList = ref([] as string[])
const prepay = ref(0)
const types = Object.values(PrepaymentType).map((item) => {
    return {
        label: item,
        value: item,
    }
})
const methods = Object.values(PaymentMethod).map((item) => {
    return {
        label: item,
        value: item,
    }
})
const measure = Object.values(PaymentMeasure).map((item) => {
    return {
        label: item,
        value: item,
    }
})
const openSigntaure = ref(false)
const image = ref("")
const disabled = ref(false)
const start = ref("" as unknown as Date)
const end = ref("" as unknown as Date)
const file = ref(null)
const fileName = ref("")

onMounted( () => {
    loading.loading()
    axios.cities.getCurrentCity()
    .then((city: any) => {
        loading.load()
        cityData.value = city.data
    })
    .catch((err: any) => {
        loading.load()
        toast('error', err + ' Не удалось получить информацию по городу')
    })
} )

function addOrderService() {
    let service = {
        title: "",
        measurement: "",
        quantity: 0,
        cost: 0,
        price: 0,
    }
    createOrder.value.services?.push(service)
}
function removeOrderService(title: string) {
    createOrder.value.services = createOrder.value.services?.filter((item) =>  item.title !== title )
}
function setCemetry(item: string) {
    createOrder.value.information.cemetery = item
}
function setMeasurement(service: OrderService) {
    service.measurement = listService.find(item => item.name === service.title)?.measurement || ""
}





function maxDiscountInput(value: number) {
    if(createOrder.value.price.discountMeasure === PaymentMeasure.percent) {
        if(value > 100) {
            createOrder.value.price.discountValue = 100
        } else {
            createOrder.value.price.discountValue = value
        }
    } else {
        if(value > createOrder.value.price.total) {
            createOrder.value.price.discountValue = createOrder.value.price.total
        } else {
            createOrder.value.price.discountValue = value
        }
    }
}



function maxPrepaymentInput(value: number) {
    if(prepayment.value.prepaymentMeasure === PaymentMeasure.percent) {
        if(value > 100) {
            prepayment.value.prepaymentValue = 100
        } else {
            prepayment.value.prepaymentValue = value
        }
    } else {
        if(value > createOrder.value.price.final) {
            prepayment.value.prepaymentValue = createOrder.value.price.final
        } else {
            prepayment.value.prepaymentValue = value
        }
    }
}

const reader = new FileReader();
reader.onload = function(event: any) {
  const base64String = event.target.result;
  createOrder.value.uploadImage = base64String
};

function getFile(event: any) {
    file.value = event.target.files[0];
    fileName.value = event.target.files[0].name;
    //@ts-ignore
    reader.readAsDataURL(file.value);
}

const signature = ref(null)
function createSignature() {
    //@ts-ignore
    if(signature.value.isEmpty()) {
        //@ts-ignore
        signature.value.clear()
        image.value = ""
    } else {
        //@ts-ignore\
        image.value = signature.value.save('image/png')
        //@ts-ignore
        signature.value.clear()
        openSigntaure.value = false
    }
}
function clearSignature() {
    //@ts-ignore
    signature.value.clear()
    image.value = ""
}
function closeSignature() {
    //@ts-ignore
    signature.value.clear()
    openSigntaure.value = false
}

async function saveOrder(order: createOrderType) {
    loading.loading()
    orderLocal.saveOrder(order)
    toast('success', 'Заказ успешно создан')
    loading.load()
    router.push({name: 'main'})
}

async function  createNewOrder() {
    try {
        loading.loading()
        await axios.order.createOrder(createOrder.value)
        loading.load()
        toast('success', 'Заказ успешно создан')
        router.push({name: 'main'})
    } catch (error: any) {
        loading.load()
        toast('error', error.response.data.message)
    }
}
async function submit() {
    try {
        createOrder.value.signImage = image.value
        await createOrderShema.validate(createOrder.value, { abortEarly: false })
        errors.value = {
            client: "",
            clientPhone: "",
            deceased: "",
            deathDate: "",
            cemetery: "",
            graveDistrict: "",
            graveRow: "",
            gravePlace: "",
            method: "",
            startAt: "",
            endAt: "",
        }
        if(onlineStore.isOnline === true) {
            return await createNewOrder()
        } else {
            return await saveOrder(createOrder.value)
        }
    } catch (error: any) {
        console.log(error)
        error.inner.forEach((error: any) => {
            let errPath = error.path.split(".")
            // @ts-ignore
            errors.value[errPath[errPath.length - 1]] = error.message;
            if (error.path.includes("services")) {
                toast('error', "выберите услугу")
            }
        });
        if(errors.value.deathDate != "") {
            toast('error', errors.value.deathDate)
        }
        if(errors.value.method != "") {
            toast('error', errors.value.method)
        }
        if(errors.value.startAt != "") {
            toast('error', errors.value.startAt)
        }
        if(errors.value.endAt != "") {
            toast('error', errors.value.endAt)
        }
    }
}



// const dateStart = computed(() => createOrder.value.dates.startAt = start.value.toLocaleDateString())
// const dateEnd = computed(() => createOrder.value.dates.endAt = end.value.toLocaleDateString())
const serviceNameList = computed(() => {
    const usedNames = createOrder.value.services?.map(service => service.title)
    return [...listService].filter(service => !usedNames?.includes(service.name)).map(service => service.name)

})

const discountPrice = computed(() => createOrder.value.price.final = createOrder.value.price.total - createOrder.value.price.discount)



watch(() => start.value,() => {
    createOrder.value.dates.startAt = start.value
})

watch(() => end.value,() => {
    createOrder.value.dates.endAt = end.value
})

watch(() => createOrder.value.services, (newVal:typeof createOrder.value.services) => {
    if(newVal) {
        createOrder.value.price.total = newVal.reduce<number>((old, curr) => {
            return old +  +curr.price
        }, 0);
    }
}, {deep: true})

watch(() => [createOrder.value.price.discount, createOrder.value.price.total], () => {
    createOrder.value.price.final = createOrder.value.price.total - createOrder.value.price.discount
})

watch(image, () => {
        if(image.value != "") {
            disabled.value = true
        } else {
            disabled.value = false
        }
})

watch(() => [createOrder.value.price.discountMeasure], () => {
    if(createOrder.value.price.discountMeasure === PaymentMeasure.percent) {
        maxDiscountInput(createOrder.value.price.discountValue)
    }
})

watch(() => [prepayment.value.prepaymentMeasure], () => {
    if(prepayment.value.prepaymentMeasure === PaymentMeasure.percent) {
        maxPrepaymentInput(prepayment.value.prepaymentValue)
    }
})

watch(() => [errors.value], () => {
    let { method, startAt, endAt, ...graveErrors } = errors.value
    graveError.value = graveErrors
}, {deep: true})

watch(() => [createOrder.value.price.final], ()=> {
    createOrder.value.costs.raw = ( createOrder.value.costs.rawValue * createOrder.value.price.final) / 100
    createOrder.value.costs.materials = ( createOrder.value.costs.materialsValue * createOrder.value.price.final) / 100
    createOrder.value.costs.concrete = ( createOrder.value.costs.concreteValue * (createOrder.value.price.final - createOrder.value.costs.raw - createOrder.value.costs.materials)) / 100
    createOrder.value.costs.install = ( createOrder.value.costs.installValue * (createOrder.value.price.final - createOrder.value.costs.raw - createOrder.value.costs.materials)) / 100
    createOrder.value.costs.brigade = ( createOrder.value.costs.brigadeValue * createOrder.value.price.final) / 100
    createOrder.value.costs.departure = ( createOrder.value.costs.departureValue * createOrder.value.price.final) / 100
})

watchEffect(() => {
    switch(prepayment.value.prepaymentType) {
        case PrepaymentType.prepayment:
            createOrder.value.prepayment = {
                date: new Date(),
                amount: 0,
                method: "",
            }
            prepayment.value.prepaymentValue = 50
            prepayment.value.prepaymentMeasure = PaymentMeasure.percent
            break;
        case PrepaymentType.fullPrepayment:
            createOrder.value.prepayment = {
                date: new Date(),
                amount: 0,
                method: "",
            }
            prepayment.value.prepaymentValue = 100
            prepayment.value.prepaymentMeasure = PaymentMeasure.percent
            break;
        default:
            createOrder.value.prepayment = null
    }
})

watchEffect(() => {
    if(createOrder.value.price.discountMeasure === PaymentMeasure.percent) {
        createOrder.value.price.discount  = (createOrder.value.price.discountValue * createOrder.value.price.total) / 100
    } else {
        createOrder.value.price.discount  = createOrder.value.price.discountValue
    }
})

watchEffect(() => {
    if(createOrder.value.prepayment){
        if(prepayment.value.prepaymentMeasure === PaymentMeasure.percent) {
            createOrder.value.prepayment.amount =  (prepayment.value.prepaymentValue * createOrder.value.price.final) / 100
        } else {
            createOrder.value.prepayment.amount = prepayment.value.prepaymentValue
        }
    }
})



</script>

<style lang="sass">
    .create-order
        &__title
            font-family: 'Roboto'
            font-weight: 500
            font-size: 32px
            color: var(--brown)
            &_min
                font-family: 'Roboto'
                font-weight: 500
                font-size: 24px
                color: var(--brown)
        &__required
            display: block
            font-family: 'Roboto'
            font-weight: 300
            font-size: 18px
            color: var(--red)
            margin-top: 10px
        &__item
            margin-top: 60px
        &__button
            display: flex
            gap: 5px
            padding: 12px 28px
            border: 2px solid var(--gold)
            color: var(--gold)
            font-family: 'Roboto'
            font-weight: 500
            font-size: 14px
            margin-top: 30px
        &__submit
            background: var(--gold)
            padding: 14px 30px
            max-width: 386px
            width: 100%
            margin-top: 60px
            font-family: 'Roboto'
            font-weight: 600
            font-size: 18px
            color: var(--white)
        &__remove
            margin-top: 60px
            font-family: 'Roboto'
            font-weight: 300
            font-size: 18px
            color: var(--gray)
            text-decoration: underline
        &__date
            display: grid
            grid-template-columns: repeat(2, calc( 50% - 30px ))
            gap: 30px
            margin-top: 30px
            &-item
                margin-top: 14px
            @media (max-width: 450px)
                grid-template-columns: 100%
        &__file
            display: block
            &-box
                display: flex
                align-items: center
                margin-top: 40px
            &-text
                font-family: 'Roboto'
                font-weight: 400
                font-size: 14px
                text-decoration-line: underline
                color: var(--gold)
                margin-left: 20px
                flex: 1
                overflow: hidden
                white-space: nowrap
                text-overflow: ellipsis
            &-input
                display: none
            &-button
                border: 2px solid var(--gold)
                border-radius: 3px
                padding: 10px 18px
                font-weight: 500
                font-size: 14px
                color: var(--gold)
                flex: 0
    .information
        &__list
            margin-top: 30px
            display: grid
            grid-template-columns: repeat(3, 4fr)
            gap: 30px
            @media (max-width: 1200px)
                grid-template-columns: repeat(2, 6fr)
            @media (max-width: 1200px)
                grid-template-columns: 100%
            &_sub
                display: grid
                grid-template-columns: repeat(3, 120px)
                gap: 13px
                @media (max-width: 430px)
                    grid-template-columns: 100%
        &__select
            max-width: 360px
            width: 100%
            padding: 20px
            &-item
                display: block
                background: none
                width: 100%
                border: none
                color: var(--brown)
                font-size: 18px
                &:not(:first-child)
                    margin-top: 20px
        &__date
            width: 100%
            margin-top: 10px
            .dp__input
                padding: 12px 12px
                border: 1px solid var(--dp-background-color)
            .dp__input_icon_pad
                padding-left: 35px
            &-box
                width: 100%
            &-title
                color: var(--brown)
                font-family: "Roboto"
                font-weight: 300
                font-size: 18px
    .service
        &__list
            margin-top: 30px
            display: grid
            grid-template-columns: repeat(3, 4fr)
            gap: 30px
            @media (max-width: 1450px)
                grid-template-columns: repeat(2, 4fr)
                gap: 30px
            @media (max-width: 990px)
                grid-template-columns: 100%
                gap: 30px
        &__item
            width: 100%
            padding: 20px
            border: 2px solid var(--gold)
            .vs__dropdown-toggle
                width: 100%
                height: 48px
                border: none
                background-color: var(--gray-light)
                margin-top: 10px
                padding: 14px
                align-items: center
            .vs__search
                &::placeholder
                    color: var(--gray)
            .vs__actions
                flex: none
            .vs__clear
                display: none
        &__delete
            padding: 5px 10px
            width: 100%
            border: 1px solid var(--gray)
            border-radius: 3px
            background: none
            display: flex
            justify-content: center
            align-items: center
            gap: 10px
            font-family: 'Roboto'
            font-weight: 500
            font-size: 14px
            color: var(--gray)
        &__title
            display: block
            margin-top: 30px
            color: var(--brown)
            font-family: 'Roboto'
            font-weight: 300
            font-size: 18px
        &__index
            display: block
            width: 100%
            margin-top: 10px
            padding: 5px
            text-align: center
            background-color: var(--brown)
            color: var(--white)
            font-family: 'Roboto'
            font-weight: 600
            font-size: 18px
            border-radius: 3px
        &__checklist
            margin-top: 30px
        &__parametrs
            margin-top: 30px
            display: grid
            grid-template-columns: repeat(2, calc( 50% - 15px ))
            gap: 30px
            @media (max-width: 400px)
                grid-template-columns: 100%
    .additional-service
        &__field
            width: 100%
            height: 150px
            margin-top: 20px
            padding: 14px
            display: block
            resize: none
            background: var(--gray-light)
            border: none
            border-radius: 3px
            color: var(--gray-dark)
            font-family: 'Roboto'
            font-weight: 400
            font-size: 18px
            &::placeholder
                color: var(--gray)
    .payment
        &__label
            display: block
            font-family: 'Roboto'
            font-weight: 300
            font-size: 18px
            color: var(--brown)
            b
                color: var(--red)
            &_big
                font-family: 'Roboto'
                font-weight: 500
                font-size: 24px
                color: var(--brown)
        &__value
            margin-top: 15px
            font-family: 'Roboto'
            font-weight: 500
            font-size: 24px
            color: var(--brown)
            &_gold
                font-size: 30px
                color: var(--gold)
            &_flex
                display: flex
                gap: 16px
                align-items: start
            &_mt
                margin-top: 30px
    .cost
        &__list
            margin-top: 30px
            display: grid
            grid-template-columns: repeat(2, 200px)
            gap: 30px
            @media (max-width: 450px)
                grid-template-columns: 100%
    .radio
        &__list
            margin-top: 14px
            flex: none
        &__item
            display: flex
            align-items: center
            font-family: 'Roboto'
            font-weight: 400
            font-size: 18px
            color: var(--gray-dark)
            input
                width: 20px
                height: 20px
                margin-right: 8px
            &:not(:first-child)
                margin-top: 14px
    .discount
        margin-top: 30px
        display: grid
        grid-template-columns: repeat(2, 178px)
        gap: 30px
        @media (max-width: 450px)
                grid-template-columns: 100%
    .pay
        margin-top: 30px
        display: grid
        grid-template-columns: repeat(2, 200px)
        gap: 30px
        @media (max-width: 450px)
                grid-template-columns: 100%
    .v-popper__arrow-container
        display: none
    .signature
        &-box
            border: 1px solid var(--gray)
            border-radius: 5px
            overflow: hidden
            position: relative
        &__undo
            height: 20px
            &-box
                position: absolute
                top: 5px
                right:5px
                z-index: 2
                border: none
                background: none
        &__button
            text-transform: uppercase
            color: var(--white)
            background: var(--gold)
            width: 100%
            padding: 13px 10px
            &_gray
                background: none
                color: var(--gray)
                border: 1px solid var(--gray)
                margin-top: 20px
            &-box
                width: 100%
                margin-top: 20px
</style>