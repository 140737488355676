<template>
    <div class="users">
        <form method="post" @submit.prevent="submit" class="create-user">
            <h3 class="create-user__title">Создание нового пользователя</h3>
            <ul class="create-user__list">
                <li class="create-user__item">
                    <span class="create-user__text">Email</span>
                    <input class="create-user__field" :class="{error: errors.email}" @input="errors.email =''" v-model="createUser.email" type="email" placeholder="testmail@mail.ru">
                </li>
                <li class="create-user__item">
                    <span class="create-user__text">Логин</span>
                    <input class="create-user__field" :class="{error: errors.login}" @input="errors.login =''" v-model="createUser.login" type="text" placeholder="nickname">
                </li>
                <li class="create-user__item">
                    <span class="create-user__text">Пароль</span>
                    <span class="create-user__text_gray">генерируется автоматически</span>
                </li>
                <li class="create-user__item">
                    <span class="create-user__text">Роль</span>
                    <div class="create-user__role">
                        <label v-for="item in roles">
                            <input type="radio" v-model="createUser.role" :value="item" >
                            {{item}}
                        </label>
                    </div>
                </li>
                <li class="create-user__item">
                    <span class="create-user__text">ФИО</span>
                    <input class="create-user__field" :class="{error: errors.fullname}" @input="errors.fullname =''" v-model="createUser.fullname" type="text" placeholder="Иванов Иван Иванович">
                </li>
                <li class="create-user__item">
                    <span class="create-user__text">Номер телефона</span>
                    <input class="create-user__field" :class="{error: errors.phone}" @input="errors.phone =''" v-model="createUser.phone" type="tel" placeholder="+7 (999) 999-99-99">
                </li>
                <li class="create-user__item">
                    <span class="create-user__text">Регион</span>
                    <div class="create-user__role create-user__role_reg">
                        <label v-for="item in citiesList">
                            <input type="radio" v-model="createUser.regionId" :value="item._id" >
                            {{item.slug}}
                        </label>
                    </div>
                </li>
            </ul>
            <button-component class="create-user__button" buttonText="создать пользователя"/>
        </form>
        <users-list-component/>
    </div>
</template>
<script setup lang="ts">
import buttonComponent from '../shared/buttonComponent.vue';
import usersListComponent from '../shared/usersListComponent.vue';
import toast from "../app/lib/hooks/errorToast"
import axios from '../app/lib/api'
import {createUserShema} from '../app/lib/shemas/userShema';
import {useLoadingStore } from '../app/store/store';
import { UserRole } from '../app/lib/types/users';
import { onMounted, ref, watch } from 'vue';
import {Cities} from '../app/lib/types/cities'

const loading = useLoadingStore()

const createUser = ref({
    email: '',
    login: '',
    fullname: '',
    phone: '',
    region: 'ВН',
    role: 'Исполнитель',
    regionId: ''
})
const errors = ref({
    email: '',
    login: '',
    fullname: '',
    phone: '',
    region: '',
    role: '',
})

const citiesList = ref([] as unknown as Cities[])
const user = ref(true)
const roles = Object.values(UserRole)

onMounted(() => {
    axios.cities.getCitiesList()
    .then((r: any) => {
        citiesList.value = r.data
    })
    .catch((err: any) => {
        loading.load()
        toast('error', err + ' Не удалось получить список городов')
    })
})

const createNewUser = async () => {
    try {
        const res = await axios.users.createNewUser(createUser.value)
        toast('success', 'Пользователь создан')
    } catch (error: any) {
        toast('error', error.response.data.message)
    }
}

const submit = async () => {
    try {
        await createUserShema.validate(createUser.value, { abortEarly: false })
        errors.value = {
                    email: '',
                    login: '',
                    fullname: '',
                    phone: '',
                    region: '',
                    role: '',
        }
        return await createNewUser()
    } catch (error:any) {
        error.inner.forEach((error: any ) => {
                //@ts-ignore
                errors.value[error.path] = error.message;
        });
    }
}



watch(() => createUser.value.region, () => {
        if(createUser.value.region != "") {
            errors.value.region = ''
        }
})

</script>
<style lang="sass">
    .create-user
        max-width: 906px
        width: 100%
        display: block
        &__title
            display: block
            width: 100%
            padding: 22px 30px
            background: var(--brown)
            font-family: 'Roboto'
            font-weight: 500
            font-size: 22px
            color: var(--white)
            border-radius: 5px 5px 0 0
        &__list
            background-color: var(--gray-light)
            padding: 0 30px
            width: 100%
            border-radius: 0 0 5px 5px
        &__item
            display: flex
            gap: 230px
            align-items: center
            height: 80px
            &:not(:last-child)
                border-bottom: 1px solid var(--gray-prelight)
            @media (max-width: 980px)
                flex-direction: column
                align-items: stretch
                gap: 30px
                padding: 10px
                height: auto
        &__text
            font-family: 'Roboto'
            font-weight: 600
            font-size: 18px
            color: var(--brown)
            width: 63px
            &_gray
                width: auto
                font-weight: 400
                color: var(--gray)
        &__field
            display: block
            border: 1px solid var(--brown)
            background: none
            font-family: 'Roboto'
            font-weight: 400
            font-size: 18px
            color: var(--brown)
            padding: 14px
            max-width: 387px
            width: 100%
            border-radius: 3px
            &:placeholder
                color: var(--gray)
            &:focus
                border-color: var(--gold)
            &.error
                border: 1px solid var(--red)
        &__role
            display: flex
            align-items: center
            flex-wrap: wrap
            gap: 30px
            &_reg
                max-width: 300px
                width: 100%
                padding: 20px 0
                gap: 15px 30px
        &__button
            margin: 0 auto
            margin-top: 20px
            background: var(--gold)
            padding: 11px 53px
            color: var(--white)
            font-size: 14px
            text-transform: uppercase
</style>