<template>
    <div class="order-list" :class="{mt_80: props.margin}">
        <div class="order-list__title-box">
            <h2 class="order-list__title">Последние заказы</h2>
            <router-link to="/orders/list" class="order-list__link" v-if="props.link">смотреть все</router-link>
        </div>
        <ul class="order-list__list">
            <li class="order-list__item" v-for="(order, index) in orders" :key="index">
                <div class="order-list__content">
                    <span class="order-list__text order-list__index">№{{index + 1}}</span>
                    <span class="order-list__text order-list__name">{{order?.information.deceased}}</span>
                    <span class="order-list__text order-list__text_gray order-list__type">{{order.information.cemetery}}</span>
                    <span class="order-list__text order-list__id">{{ order.number }}</span>
                    <span class="order-list__text order-list__price">{{ order.price.final }} &#8381;</span>
                    <v-select 
                        :searchable="false" 
                        :autoscroll="false" 
                        :modelValue="order.status" 
                        v-if="order.userId === userId || ( role === UserRole.ADMIN || role === UserRole.CHIEF_MANAGER)" 
                        :options="statuses" 
                        @option:selected="(status: OrderStatus) => { setStatus(order._id, status) }"
                    />
                    <span class="order-list__text order-list__status" v-else>{{ order.status }}</span>
                </div>
                <div class="order-list__action-box">
                    <VDropdown>
                        <button class="order-list__action">
                            <icon-component class="order-list__icon" name="action" width="32" height="32"/>
                        </button>
                        <template #popper>
                          <div class="order-list__option-box">
                            <router-link :to="{ name: 'order', params: { orderId: order._id } }" target="_blank" class="order-list__option">Подробнее</router-link>
                            <button class="order-list__option" v-if="role !== UserRole.USER" @click="openPopup = order._id">Платежи</button>
                            <button class="order-list__option" v-if="role !== UserRole.USER && role !== UserRole.MANAGER" @click="isDelete = order._id">Удалить заказ</button>
                          </div>
                        </template>
                    </VDropdown>
                </div>
                <popup-component :mt="true" :isOpen="openPopup === order._id" @close="openPopup = '', activePayment = false">
                    <template #title>Платежи</template>
                    <template #additional_title_info>
                        <span class="payments__title payments__title_gold">Номер заказа: {{order.region.slug + order.index}}</span>
                        <span class="payments__title payments__title_gold">ФИО покойного: {{order?.information.deceased}}</span>
                    </template>
                    <template #content>
                        <div class="payments__information">
                            <div class="payments__information-item">
                                <span class="payments__title">Цена</span>
                                <span class="payments__text payments__text_np">{{order.price.total}} руб</span>
                            </div>
                            <div class="payments__information-item">
                                <span class="payments__title">Скидка</span>
                                <span class="payments__text payments__text_np">{{order.price.discount}} руб</span>
                            </div>
                            <div class="payments__information-item">
                                <span class="payments__title">Цена со скидкой</span>
                                <span class="payments__text payments__text_np">{{order.price.final}} руб</span>
                            </div>
                            <div class="payments__information-item">
                                <span class="payments__title">Оплачено</span>
                                <span class="payments__text payments__text_np">{{order.payments.reduce((sum, item) => {return sum + item.amount}, 0)}} руб</span>
                            </div>
                            <div class="payments__information-item">
                                <span class="payments__title payments__title_gold">Остаток</span>
                                <span class="payments__text payments__text_np">{{(order.price.final - order.payments.reduce((sum, item) => {return sum + item.amount}, 0)).toFixed(2)}} руб</span>
                            </div>
                        </div>
                        <ul class="payments">
                            <li class="payments__item">
                                <span class="payments__text">№</span>
                                <span class="payments__text">Платеж</span>
                                <span class="payments__text payments__text_gray">Метод</span>
                                <span class="payments__text">Дата</span>
                            </li>
                            <li class="payments__item" v-for="(item, index) in order.payments">
                                <span class="payments__text">{{index + 1}}</span>
                                <span class="payments__text">{{`${item.amount} руб.`}}</span>
                                <span class="payments__text payments__text_gray">{{item.method}}</span>
                                <span class="payments__text">{{new Date(item.date).toLocaleDateString()}}</span>
                            </li>
                        </ul>
                        <div class="payments-box" v-if="+(order.price.final - order.payments.reduce((sum, item) => {return sum + item.amount}, 0)).toFixed(2) > 0">
                            <button-component buttonText="Добавить платеж" @click="activePayment = true" v-if="!activePayment && role !==UserRole.MANAGER"/>
                            <OrderPaymentsComponent :id="order._id" @close="activePayment = false" @newPayment="setPayment" v-if="activePayment" :remains="+(order.price.final - order.payments.reduce((sum, item) => {return sum + item.amount}, 0)).toFixed(2)"/>
                        </div>
                    </template>
                </popup-component>
                <popup-component :mt="true" :isOpen="isDelete === order._id" @close="isDelete = ''">
                    <template #title>
                        Вы хотите удалить заказ?
                    </template>
                    <template #content>
                        <div class="delete-box">
                            <button-component class="delete delete_success" buttonText="Да" @click="deleteOrder(order._id)"/>
                            <button-component class="delete delete_close" buttonText="Нет" @click="isDelete = ''"/>
                        </div>
                    </template>
                </popup-component>
            </li>
        </ul>
    </div>
</template>
<script setup lang="ts">
import buttonComponent from './buttonComponent.vue';
import OrderPaymentsComponent from './orderPaymentsComponent.vue';
import PopupComponent from '../entities/popupComponent.vue'
import axios from '../app/lib/api';
import { Order, OrderPayment, OrderStatus } from '../app/lib/types/orders';
import iconComponent from './iconComponent.vue';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { onMounted, ref, watch } from 'vue';
import toast from '../app/lib/hooks/errorToast';
import { omit } from 'lodash';
import { UserRole } from '../app/lib/types/users';
import {useLoadingStore} from '../app/store/store';
import { userInfo } from 'os';
dayjs.extend(isBetween)

const props = defineProps({
    margin: {
        type: Boolean
    },
    link: {
        type: Boolean
    },
    sorting: {
        type: Boolean
    },
    date: {
        type: Array
    },
    filtered: {
        type: Boolean
    },
    quantity: {
        type: Number,
        default: 0
    }
})

const loading = useLoadingStore()
const orders = ref([] as unknown as Order[])
const statuses = Object.values( omit(OrderStatus, 'archive'))
const role = localStorage.getItem("role")
const openPopup = ref("")
const isDelete = ref("")
const activePayment = ref(false)
const userId = ref("")

onMounted(() => {
    axios.users.getUserInfo()
    .then((res: any) => {
        userId.value = res.data.userId
    })
    
    loading.loading()
    let filter = {
        perPage: props.quantity,
        search: {searchBy: [{field: 'number', value: ''}]}
    }
    axios.order.getOrderList(filter)
    .then((res: any) => {
        orders.value = res.data.result
        loading.load()
    })
    .catch((err: any) => {
        loading.load()
        toast('error', "Не удалось загрузить заказы:" + err)
    })
})

async function setStatus(id: string, status: OrderStatus) {
    try {
        loading.loading()
        await axios.order.updateOrderStatus(id, status)
        const order = orders.value.find(order => order._id === id )
        if(order){
            order.status = status
        }
        loading.load()
    } catch (error: any) {
        loading.load()
        toast("error", error.response.data.message)
    }
}

function setPayment(data: {id: string, payment: OrderPayment}) {
    const order = orders.value.find(order => order._id === data.id)
    if(order){
        order.payments.push(data.payment)
    }
}

async function deleteOrder(id: string) {
    try {
        loading.loading()
        await axios.order.updateOrderStatus(id, OrderStatus.archive)
        orders.value = orders.value.filter(order => order._id !== id)
        loading.load()
    } catch (error: any) {
        toast("error", error.response.data.message)
        loading.load()
    }
}


</script>
<style lang="sass">
    .mt_80
        margin-top: 80px
    .v-popper--theme-dropdown
        .v-popper__inner
            border: none
            border-radius: 0
    .payments
        display: block
        &-box
            margin-top: 30px
        &__information
            display: grid
            grid-template-columns: repeat(5, 1fr)
            gap: 20px
            @media (max-width: 915px)
                grid-template-columns: repeat(3, 1fr)
            @media (max-width: 600px)
                grid-template-columns: repeat(2, 1fr)
            @media (max-width: 440px)
                grid-template-columns: 100%
            &-item
                display: flex
                flex-direction: column
                align-items: center
                gap: 10px
        &__title
            font-family: 'Roboto'
            font-weight: 600
            font-size: 18px
            color: var(--brown)
            display: block
            &_gold
                color: var(--gold)
        &__item
            margin-top: 20px
            display: grid
            grid-template-columns: repeat(4, 200px)
            gap: 30px
            padding-bottom: 10px
            border-bottom: 1px solid var(--gray)
            @media (max-width: 1020px)
                grid-template-columns: repeat(2, 200px)
            @media (max-width: 560px)
                grid-template-columns: 280px
        &__text
            display: block
            font-family: 'Roboto'
            font-weight: 400
            font-size: 18px
            color: var(--brown)
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
            padding: 10px
            &_gray
                color: var(--gray)
            &_np
                padding: 0
    .order-list
        max-width: 1011px
        width: 100%
        &__title
            display: block
            font-family: 'Roboto'
            font-weight: 500
            font-size: 32px
            color: var(--brown)
            &-box
                width: 100%
                display: flex
                align-items: center
                flex-wrap: wrap
                gap: 30px
        &__link
            display: block
            font-family: 'Roboto'
            font-weight: 400
            font-size: 20px
            color: var(--gold)
            text-decoration: underline
        &__sort
            display: flex
            align-items: center
            flex-wrap: wrap
            gap: 10px
            &-box
                display: flex
                align-items: center
                gap: 40px
                @media (max-width: 500px)
                    flex-wrap: wrap
            &-button
                display: block
                background: none
                border: none
                font-family: 'Roboto'
                font-weight: 400
                font-size: 16px
                color: var(--brown)
        &__icon
            display: block
            color: var(--brown)
        &__list
            display: block
            margin-top: 30px
            width: 100%
        &__item
            width: 100%
            display: flex
            align-items: center
            padding: 24px 30px
            border-bottom: 1px solid var(--gray-light)
            &:hover
                @media (min-width: 540px)
                    background-color: var(--gold-light)
        &__content
            display: flex
            align-items: center
            flex-wrap: wrap
            .vs__dropdown-toggle
                width: 150px
                height: 30px
                border: none
            .vs__search
                //display: block
            .vs__actions
                flex: none
            .vs__clear
                display: none
        &__text
            font-family: 'Roboto'
            font-weight: 400
            font-size: 18px
            color: var(--brown)
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
            &_gray
                color: var(--gray)
        &__action
            border: none
            background: none
            &-box
                margin-left: auto
        &__option
            display: block
            border: none
            background: none
            font-family: 'Roboto'
            font-weight: 600
            font-size: 14px
            color: var(--brown)
            &:not(:first-child)
                margin-top: 20px
            &-box
                width: 200px
                padding: 20px
                border-radius: 3px
        &__name
            width: 280px
        &__index
            width: 160px
        &__type
            width: 217px
        &__id
            width: 120px
        &__price
            width: 120px
        &__status
            width: 140px
    .delete
        padding: 12px 28px
        border-width: 2px
        border-style: solid
        &-box
            display: flex
            justify-content: space-between
        &_success
            border-color: var(--green)
            color: var(--green)
        &_close
            border-color: var(--red)
            color: var(--red)
    .table 
        width: 100%
        border: none
        margin-bottom: 20px
        overflow-x: scroll
    .table 
        thead 
            th 
                padding: 10px
                font-weight: 500
                font-size: 16px
                line-height: 20px
                text-align: left
                color: #444441
                border-top: 2px solid #716561
                border-bottom: 2px solid #716561
    
        tbody 
            td 
                padding: 10px
                font-size: 14px
                line-height: 20px
                color: #444441
                border-top: 1px solid #716561
    
</style>