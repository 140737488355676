<template>
    <div class="app">
        <preloaderComponent/>
        <reloadPWA/>
        <router-view  :key="$route.fullPath"></router-view>
    </div>
</template>
<script setup lang="ts">
import { useRegisterSW } from 'virtual:pwa-register/vue';
import preloaderComponent from '../shared/preloaderComponent.vue';
import reloadPWA from './reloadPWA.vue';
import {useLoadingStore, useOnlineStore } from './store/store';
import { onMounted } from 'vue';
import toast from './lib/hooks/errorToast';
const onlineStore = useOnlineStore()

const loading = useLoadingStore()


const intervalMS = 60 * 60 * 1000

const updateServiceWorker = useRegisterSW({
    onRegistered(r) {
        r && setInterval(() => {
        r.update()
        console.log("auto reload")
        }, intervalMS)
    }
})


function handleOnline() {
    onlineStore.getOnline
    toast("success", "Подключение к Интернету восстановлено")
}

function handleOffline() {
    onlineStore.getOffline
    toast("error", "Вы находитесь в оффлайн-режиме")
}

onMounted(() => {
    loading.load()
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    if( navigator.onLine ) {
        handleOnline
    } else {
        handleOffline
    }
});

</script>
<style  lang="sass">
</style>