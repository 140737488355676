<template >
    <div page-card>
        <h2 class="order-information__title">Наряд-заказ на благоустройство могилы <b>{{ order.number }}</b></h2>
        <span class="order-information__master">Мастер: {{ order.creatorContacts?.fullname }}</span>
        <span class="order-information__required">* — поля, обязательные для заполнения</span>
        <div class="order-information__form">
            <div class="order-information__item information">
                <h3 class="order-information__title_min">Даты заказа</h3>
                <div class="information__list">
                    <div class="information__item">
                        <span class="information__title">Дата заключения договора</span>
                        <span class="information__text">{{orderInfomation.createdAt ? new Date(orderInfomation.createdAt).toLocaleDateString() : 'не указано'}}</span>
                    </div>
                    <div class="information__item">
                        <span class="information__title">Дата закрытия договора</span>
                        <span class="information__text">{{orderInfomation.closedAt ? new Date(orderInfomation.closedAt).toLocaleDateString() : 'не указано'}}</span>
                    </div>
                </div>
            </div>
            <div class="order-information__item information">
                <h3 class="order-information__title_min">Контактная информация</h3>
                <div class="information__list">
                    <div class="information__item" v-if="orderInfomation.information.client">
                        <span class="information__title">Ф.И.О. заказчика</span>
                        <span class="information__text">{{orderInfomation.information.client}}</span>
                    </div>
                    <div class="information__item" v-if="orderInfomation.information.clientPhone">
                        <span class="information__title">Номер телефона</span>
                        <a :href="`tel:${orderInfomation.information.clientPhone}`" class="information__text">{{orderInfomation.information.clientPhone}}</a>
                    </div>
                    <div class="information__item" v-if="orderInfomation.information.clientAddress">
                        <span class="information__title">Адрес</span>
                        <span class="information__text">{{orderInfomation.information.clientAddress}}</span>
                    </div>
                    <div class="information__item" v-if="orderInfomation.information.clientEmail">
                        <span class="information__title">Email</span>
                        <a :href="`mailto:${orderInfomation.information.clientEmail}`" class="information__text">{{orderInfomation.information.clientEmail}}</a>
                    </div>
                    <div class="information__item" v-if="orderInfomation.information.deceased">
                        <span class="information__title">Ф.И.О. покойного</span>
                        <span class="information__text">{{orderInfomation.information.deceased}}</span>
                    </div>
                    <div class="information__item" v-if="orderInfomation.information.cemetery">
                        <span class="information__title">Место установки</span>
                        <span class="information__text">{{orderInfomation.information.cemetery}}</span>
                    </div>
                    <div class="information__item" v-if="orderInfomation.information.cemetery">
                        <span class="information__title">Дата смерти</span>
                        <span class="information__text">{{orderInfomation.information.deathDate ? new Date(orderInfomation.information.deathDate).toLocaleDateString() : 'не указано'}}</span>
                    </div>
                    <div class="information__list_sub">
                        <div class="information__item" v-if="orderInfomation.information.graveDistrict">
                            <span class="information__title">Квадрат</span>
                            <span class="information__text">{{orderInfomation.information.graveDistrict}}</span>
                        </div>
                        <div class="information__item" v-if="orderInfomation.information.graveRow">
                            <span class="information__title">Ряд</span>
                            <span class="information__text">{{orderInfomation.information.graveRow}}</span>
                        </div>
                        <div class="information__item" v-if="orderInfomation.information.gravePlace">
                            <span class="information__title">Место</span>
                            <span class="information__text">{{orderInfomation.information.gravePlace}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="order-information__item service">
                <h3 class="order-information__title_min">Список оказываемых услуг</h3>
                <div class="service__list">
                    <div class="service__item" v-for="service, index in orderInfomation.services" :key="service.title">
                        <span class="service__index">{{index + 1}}</span>
                        <div class="information__item">
                            <span class="service__title">Наименование услуги</span>
                            <span class="service__text">{{service.title}}</span>
                        </div>
                        <div class="service__parametrs">
                            <div class="information__item">
                                <span class="information__title">Размер</span>
                                <span class="information__text">{{service.quantity}}</span>
                            </div>
                            <div class="information__item">
                                <span class="information__title">Ед. изм.</span>
                                <span class="information__text">{{service.measurement}}</span>
                            </div>
                            <div class="information__item">
                                <span class="information__title">Цена за ед., руб.</span>
                                <span class="information__text">{{service.cost}}</span>
                            </div>
                            <div class="information__item">
                                <span class="information__title">Сумма, руб.</span>
                                <span class="information__text">{{service.price}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="order-information__item additional-service" v-if="orderInfomation.moreServices">
                <h3 class="order-information__title_min">Список оказываемых услуг</h3>
                <p class="additional-service__field">{{orderInfomation.moreServices}}</p>
            </div>
            <div class="order-information__item payment">
                <div class="col">
                    <div class="cost">
                        <h3 class="order-information__title_min">Расчёт стоимости</h3>
                        <div class="cost__list">
                            <div class="col" v-if="orderInfomation.price.total">
                                <div class="payment__value-box">
                                    <span class="payment__label">Сумма заказа</span>
                                    <div class="payment__value payment__value_min">{{orderInfomation.price.total}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="discount">
                        <div class="col" >
                            <div class="payment__value-box" v-if="orderInfomation.price.discount">
                                <span class="payment__label">скидка</span>
                                <div class="payment__value payment__value_min">{{orderInfomation.price.discount}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <h3 class="order-information__title_min">Сроки выполнения работ</h3>
                    <div class="order-information__date">
                        <div class="col" v-if="orderInfomation.dates.startAt">
                            <div class="payment__value-box">
                                <span class="payment__label">Начало работ</span>
                                <div class="payment__value">{{new Date(orderInfomation.dates.startAt).toLocaleDateString()}}</div>
                            </div>
                        </div>
                        <div class="col" v-if="orderInfomation.dates.endAt">
                            <div class="payment__value-box">
                                <span class="payment__label">Окончание работ</span>
                                <div class="payment__value">{{new Date(orderInfomation.dates.endAt).toLocaleDateString()}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="order-information__item order-information__comment" v-if="orderInfomation.comment">
                        <div class="order-information__item additional-service">
                            <h3 class="order-information__title_min">Комментарий к заказу</h3>
                            <p class="additional-service__field additional-service__field_text">{{orderInfomation.comment}}</p>
                        </div>
                        <button-component v-if="order.uploadImage != ''" class="button image" button-text="Посмотреть фото" @click="openImage = true"/>
                    </div>
                    <div class="order-information__item order-information__signature" v-if="orderInfomation.signImage">
                        <h3 class="order-information__title_min">Подпись заказчика</h3>
                        <img :src="orderInfomation.signImage" alt="" width="280" height="300">
                    </div>
                </div>
            </div>
        </div>
        <popup-component :mt="true" :isOpen="openImage === true" @close="openImage = false">
            <template #title>
                Фото
            </template>
            <template #content>
                <img class="upload-image" v-if="order.uploadImage != ''" :src="order.uploadImage" alt="image">
            </template>
        </popup-component>
    </div>
</template>
<script setup lang="ts">
import { PropType, ref } from 'vue';
import { useRoute } from 'vue-router';
import { Order } from '../app/lib/types/orders';
import ButtonComponent from '../shared/buttonComponent.vue';
import PopupComponent from '../entities/popupComponent.vue';

const props = defineProps({
    order: {
        type: Object as PropType<Order>,
        required: true
    }
})
const router = useRoute()
let orderInfomation: Order = props.order
const openImage = ref(false)

</script>

<style lang="sass">
    .order-information
        &__title
            font-family: 'Roboto'
            font-weight: 500
            font-size: 32px
            color: var(--brown)
            &_min
                font-family: 'Roboto'
                font-weight: 500
                font-size: 24px
                color: var(--brown)
            b
                color: var(--gold)
        &__master
            display: block
            font-family: 'Roboto'
            font-weight: 400
            color: var(--black)
            margin-top: 10px
        &__required
            display: block
            font-family: 'Roboto'
            font-weight: 300
            font-size: 18px
            color: var(--red)
            margin-top: 10px
        &__item
            margin-top: 60px
        &__button
            display: flex
            gap: 5px
            padding: 12px 28px
            border: 2px solid var(--gold)
            color: var(--gold)
            font-family: 'Roboto'
            font-weight: 500
            font-size: 14px
            margin-top: 30px
        &__submit
            background: var(--gold)
            padding: 14px 30px
            max-width: 386px
            width: 100%
            margin-top: 60px
            font-family: 'Roboto'
            font-weight: 600
            font-size: 18px
            color: var(--white)
        &__remove
            margin-top: 60px
            font-family: 'Roboto'
            font-weight: 300
            font-size: 18px
            color: var(--gray)
            text-decoration: underline
        &__date
            display: grid
            grid-template-columns: repeat(2, calc( 50% - 30px ))
            gap: 30px
            margin-top: 30px
            &-item
                margin-top: 14px
            @media (max-width: 450px)
                grid-template-columns: 100%
    .information
        &__list
            margin-top: 30px
            display: grid
            grid-template-columns: repeat(3, 4fr)
            gap: 30px
            @media (max-width: 1200px)
                grid-template-columns: repeat(2, 6fr)
            @media (max-width: 1200px)
                grid-template-columns: 100%
            &_sub
                display: grid
                grid-template-columns: repeat(3, 120px)
                gap: 13px
                @media (max-width: 430px)
                    grid-template-columns: 100%
        &__item
            width: 100%
            display: block
        &__title
            display: block
            color: var(--brown)
            font-family: 'Roboto'
            font-weight: 300
            font-size: 18px
        &__text
            display: block
            margin-top: 10px
            color: var(--gray-dark)
            font-family: 'Roboto'
            font-weight: 400
            font-size: 18px
    .service
        &__list
            margin-top: 30px
            display: grid
            grid-template-columns: repeat(3, 4fr)
            gap: 30px
            @media (max-width: 1450px)
                grid-template-columns: repeat(2, 4fr)
                gap: 30px
            @media (max-width: 990px)
                grid-template-columns: 100%
                gap: 30px
        &__item
            width: 100%
            padding: 20px
            border: 2px solid var(--gold)
            .vs__dropdown-toggle
                width: 100%
                height: 48px
                border: none
                background-color: var(--gray-light)
                margin-top: 10px
                padding: 14px
                align-items: center
            .vs__search
                &::placeholder
                    color: var(--gray)
            .vs__actions
                flex: none
            .vs__clear
                display: none
        &__delete
            padding: 5px 10px
            width: 100%
            border: 1px solid var(--gray)
            border-radius: 3px
            background: none
            display: flex
            justify-content: center
            align-items: center
            gap: 10px
            font-family: 'Roboto'
            font-weight: 500
            font-size: 14px
            color: var(--gray)
        &__title
            display: block
            margin-top: 30px
            color: var(--brown)
            font-family: 'Roboto'
            font-weight: 600
            font-size: 18px
        &__text
            display: block
            font-weight: 400
            font-size: 18px
            margin-top: 20px
            color: var(--gray-dark)
        &__index
            display: block
            width: 100%
            margin-top: 10px
            padding: 5px
            text-align: center
            background-color: var(--brown)
            color: var(--white)
            font-family: 'Roboto'
            font-weight: 300
            font-size: 18px
            border-radius: 3px
        &__checklist
            margin-top: 30px
        &__parametrs
            margin-top: 30px
            display: grid
            grid-template-columns: repeat(2, calc( 50% - 15px ))
            gap: 30px
            @media (max-width: 400px)
                grid-template-columns: 100%
    .additional-service
        &__field
            width: 100%
            margin-top: 20px
            padding: 14px
            display: block
            border-radius: 3px
            color: var(--gray-dark)
            font-family: 'Roboto'
            font-weight: 400
            font-size: 18px
            &_text
                background: none
    .payment
        display: grid
        grid-template-columns: repeat(2, 6fr)
        gap: 30px
        @media (max-width: 1230px)
            display: flex
            flex-direction: column-reverse
            width: 100%
        &__label
            display: block
            font-family: 'Roboto'
            font-weight: 300
            font-size: 18px
            color: var(--brown)
            b
                color: var(--red)
            &_big
                font-family: 'Roboto'
                font-weight: 500
                font-size: 24px
                color: var(--brown)
        &__value
            margin-top: 15px
            font-family: 'Roboto'
            font-weight: 500
            font-size: 24px
            color: var(--brown)
            &_min
                font-size: 20px
            &_gold
                font-size: 30px
                color: var(--gold)
            &_flex
                display: flex
                gap: 16px
                align-items: start
            &_mt
                margin-top: 30px
    .cost
        &__list
            margin-top: 30px
            display: grid
            grid-template-columns: repeat(2, 200px)
            gap: 30px
            @media (max-width: 450px)
                grid-template-columns: 100%
    .radio
        &__list
            margin-top: 14px
            flex: none
        &__item
            display: flex
            align-items: center
            font-family: 'Roboto'
            font-weight: 400
            font-size: 18px
            color: var(--gray-dark)
            input
                width: 20px
                height: 20px
                margin-right: 8px
            &:not(:first-child)
                margin-top: 14px
    .discount
        margin-top: 30px
        display: grid
        grid-template-columns: repeat(2, 178px)
        gap: 30px
        @media (max-width: 450px)
                grid-template-columns: 100%
    .pay
        margin-top: 30px
        display: grid
        grid-template-columns: repeat(2, 200px)
        gap: 30px
        @media (max-width: 450px)
                grid-template-columns: 100%
    .v-popper__arrow-container
        display: none
    .signature
        &-box
            border: 1px solid var(--gray)
            border-radius: 5px
            overflow: hidden
            position: relative
        &__undo
            height: 20px
            &-box
                position: absolute
                top: 5px
                right:5px
                z-index: 2
                border: none
                background: none
        &__button
            text-transform: uppercase
            color: var(--white)
            background: var(--gold)
            width: 100%
            padding: 13px 10px
            &_gray
                background: none
                color: var(--gray)
                border: 1px solid var(--gray)
                margin-top: 20px
            &-box
                width: 100%
                margin-top: 20px
    .upload-image
        width: 100%
        height: 100%
        object-fit: cover
        display: block
</style>