import {string, object, InferType} from "yup"

export const updateContactsShema = object({
    fullname: string(),
    email: string(),
    phone: string(),
    birthday: string(),
})

export const updateContactsFullnameShema =  string().required('Введите фио')
export const updateContactsEmailShema = string().email("Введите правильный email").required('Введите email')
export const updateContactsPhoneShema = string().required('Введите телефон')
export const updateContactsBirthdayShema = string().default("")

export type updateContactsType = InferType<typeof updateContactsShema>

export const updatePasswordShema = object({
    password: string().required('Поле не должно быть пустым'),
    newPassword: string().required('Поле не должно быть пустым'),
})

export type updatePasswordType = InferType<typeof updatePasswordShema>

export const updateEmailShema = object({
    email: string().email('Неверный email').required('Поле не должно быть пустым'),
    password: string().required('Поле не должно быть пустым'),
})

export type updateEmailType = InferType<typeof updateEmailShema>

export const forgotPasswordShema = object({
    identity: string().required('Поле не должно быть пустым'),
})

export type forgotPasswordType = InferType<typeof forgotPasswordShema>

export const resetPasswordShema = object({
    resetToken: string(),
    password: string().required("Поле не должно быть пустым"),
})

export type resetPasswordType = InferType<typeof resetPasswordShema>


