import { createApp } from 'vue';
export enum UserRole {
    ADMIN = "Администратор",
    CHIEF_MANAGER = "Главный менеджер",
    MANAGER = "Менеджер",
    USER = "Исполнитель"
}

export type UserRegion = {
    index: number,
    slug: string,
    name: string,
    district: string,
    region: string,
    postCode: number
}

export type Contacts = {
    userId: string;
    email: string;
    fullname: string;
    phone: string;
    birthday: string;
}

export type Field = "fullname" | "email" | "phone" | "birthday"

export type User = {
    _id: string;
    email: string;
    login: string;
    password: string;
    region: UserRegion;
    role: UserRole;
    contacts?: Contacts;
    createdAt: Date;
    updatedAt: Date;
}