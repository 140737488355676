import { orderPaymentType } from './../shemas/updateOrderShema';
import { OrderCosts, OrderStatus, IOrderFilter} from './../types/orders';
import { updateOrderType } from '../shemas/updateOrderShema';
import { createOrderType } from './../shemas/createOrderShema';

export default function(instance: any) {
    return {
        getOrderList(filterParams: IOrderFilter) {
            return instance.get('orders', {params: {filterParams}});
        },
        getOrdersAmount(filterParams: IOrderFilter) {
            return instance.get('orders/pricesAmout', {params: {filterParams}});
        },
        getOrder(orderId: string) {
            return instance.get(`orders/${orderId}`);
        },
        createOrder(payload: createOrderType) {
            return instance.post('orders/create', payload);
        },
        updateOrder(orderId: string, payload: updateOrderType) {
            return instance.put(`orders/${orderId}`, payload);
        },
        deleteOrder(orderId: string) {
            return instance.delete(`orders/${orderId}`);
        },
        updateOrderStatus(orderId: string, status: OrderStatus) {
            return instance.patch(`orders/${orderId}/status`, {status});
        },
        updateOrderPayment(orderId: string, payload: orderPaymentType) {
            return instance.post(`orders/${orderId}/payments`, payload);
        },
        updateOrderCosts(orderId: string, payload: OrderCosts) {
            return instance.patch(`orders/${orderId}/costs`, payload);
        },
        downloadOrders(filterParams: IOrderFilter) {
            return instance.get(`orders/download`, {params: {filterParams}, responseType: 'blob'})
        }
    }
}

export {}