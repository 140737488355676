<template>
    <div class="pdf">
        <!-- <button  @click="exportPDF">create pdf</button> -->
        <Button-component buttonText="Распечатать заказ" class="pdf-button" @click="exportPDF"/>
        <div class="pdf-box">
            <div ref="pdfOrder" class="order-pdf">
                <header class="pdf-header">
                    <div class="pdf-header__logo-container">
                        <img :src="image.logo" alt="Март" class="pdf-header__logo-container__logo">
                        <span class="pdf-header__logo-container__order-number">{{ order.number }}</span>
                    </div>
                    <span class="pdf-header__text" v-if="order.creatorContacts?.fullname"><b>Мастер:</b>{{ order.creatorContacts?.fullname}}</span>
                </header>
                <main class="pdf-main">
                    <section class="pdf-hero">
                        <div class="pdf-col">
                            <h1 class="pdf-hero__title">Наряд-заказ на благоустройство могилы</h1>
                        </div>
                        <div class="pdf-col">
                            <span class="pdf-hero__date"><b>Дата приёма:</b>{{new Date(order?.dates.startAt).toLocaleDateString()}}</span>
                            <span class="pdf-hero__date"><b>Дата выполнения:</b>{{new Date(order?.dates.endAt).toLocaleDateString()}}</span>
                        </div>
                    </section>
                    <section class="pdf-information">
                        <div class="pdf-col">
                            <div class="pdf-information__item">
                                <span class="pdf-information__title">Ф.И.О. заказчика: </span>
                                <span class="pdf-information__text">{{order?.information.client}}</span>
                            </div>
                            <div class="pdf-information__item">
                                <span class="pdf-information__title">Адрес: </span>
                                <span class="pdf-information__text">{{order?.information.clientAddress}}</span>
                            </div>
                            <div class="pdf-information__item">
                                <span class="pdf-information__title">Ф.И.О. покойного: </span>
                                <span class="pdf-information__text">{{order?.information.deceased}}</span>
                            </div>
                        </div>
                        <div class="pdf-col">
                            <div class="pdf-information__item">
                                <span class="pdf-information__title">Место установки: </span>
                                <span class="pdf-information__text">{{order?.information.cemetery}}</span>
                            </div>
                            <div class="pdf-information__item">
                                <span class="pdf-information__title">Участок: </span>
                                <span class="pdf-information__text">{{order?.information.graveDistrict}}</span>
                            </div>
                            <div class="pdf-information__item">
                                <span class="pdf-information__title">Ряд: </span>
                                <span class="pdf-information__text">{{order?.information.graveRow}}</span>
                            </div>
                            <div class="pdf-information__item">
                                <span class="pdf-information__title">Место: </span>
                                <span class="pdf-information__text">{{order?.information.gravePlace}}</span>
                            </div>
                        </div>
                    </section>
                    <section class="pdf-service">
                        <table class="pdf-table">
                            <thead class="pdf-table__head">
                                <tr class="pdf-table__item">
                                    <th class="pdf-table__title pdf-table__title_7">№</th>
                                    <th class="pdf-table__title pdf-table__title_33">Наименование услуги</th>
                                    <th class="pdf-table__title pdf-table__title_15">Размер</th>
                                    <th class="pdf-table__title pdf-table__title_15">Ед. изм.</th>
                                    <th class="pdf-table__title pdf-table__title_15">Цена за ед., ₽</th>
                                    <th class="pdf-table__title pdf-table__title_15">Сумма, ₽</th>
                                </tr>
                            </thead>
                            <tbody class="pdf-table__body">
                                <tr class="pdf-table__item" v-for="service, index in order?.services">
                                    <td class="pdf-table__text">{{index + 1}}</td>
                                    <td class="pdf-table__text">{{service.title}}</td>
                                    <td class="pdf-table__text">{{service.quantity}}</td>
                                    <td class="pdf-table__text">{{service.measurement}}</td>
                                    <td class="pdf-table__text">{{service.cost}}</td>
                                    <td class="pdf-table__text">{{service.price}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                    <section class="pdf-description" v-if="order?.moreServices || order?.comment">
                        <div class="pdf-col">
                            <h2 class="pdf-description__title" v-if="order?.moreServices">Дополнительные услуги</h2>
                            <p class="pdf-description__text">{{order?.moreServices}}</p>
                        </div>
                        <div class="pdf-col">
                            <h2 class="pdf-description__title" v-if="order?.comment">Комментарии к заказу</h2>
                            <p class="pdf-description__text">{{order?.comment}}</p>
                        </div>
                    </section>
                    <section class="pdf-payment">
                        <div class="pdf-col">
                            <div class="pdf-payment__item">
                                <span class="pdf-payment__title pdf-payment__title_min">Итоговая сумма заказа</span>
                                <span class="pdf-payment__number pdf-payment__number_gold">{{order?.price.final}}</span>
                            </div>
                            <div class="pdf-payment__item" v-if="order?.payments">
                                <span class="pdf-payment__title pdf-payment__title_min">Аванс в размере</span>
                                <span class="pdf-payment__number">{{prepay}}</span>
                            </div>
                            <div class="pdf-payment__item">
                                <span class="pdf-payment__title pdf-payment__title_min">Остаток</span>
                                <span class="pdf-payment__number">{{remains}}</span>
                            </div>
                        </div>
                        <div class="pdf-col">
                            <h2 class="pdf-payment__title">Подпись заказчика</h2>
                            <img class="pdf-payment__image" :src="order?.signImage" alt="" width="280" height="300">
                        </div>
                    </section>
                </main>
                <footer class="pdf-footer">
                    <p class="pdf-footer__text">В случае не расчёта за произведённую работу исполнитель имеет право на демонтаж данного сооружения.</p>
                    <p class="pdf-footer__text">Гарантия на установку (бетонные работы) — 12 месяцев.</p>
                    <p class="pdf-footer__text">Заказчику запрещается проверять на прочность установленный исполнителем памятник до полной сдачи работы. В противном случае гарантия аннулируется.</p>
                </footer>
            </div>
        </div>
    </div>
 </template>
<script setup lang="ts">
// @ts-ignore
import html2pdf from 'html2pdf.js'
import { computed, onMounted, PropType, ref } from 'vue';
import logo from '../assets/img/svg/logo.svg'
import sign from '../assets/img/Vector 1.png'
import { Order } from '../app/lib/types/orders';
import ButtonComponent from '../shared/buttonComponent.vue';

const props = defineProps({
    order: {
        type: Object as PropType<Order>,
        required: true,
    },
})

const image = {
    logo,
    sign
}

const prepay =  props.order?.payments.reduce((sum, item) => {return sum + item.amount}, 0)

const remains: number | undefined = props.order?.price.final - prepay

const opt = {
    margin: 30,
    filename: `${props.order?.information.client} + `,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2, logging: true, dpi: 192, letterRendering: true, width: 1920, windowWidth: 1920 },
    jsPDF: { unit: 'px', format: 'a4', orientation: 'portrait', hotfixes: ["px_scaling"] },
    pagebreak: { mode: 'css', before: ['.pdf-break'], avoid: ['.pdf-break']}
}

const pdfOrder = ref(null)

function exportPDF() {
    html2pdf().set(opt).from(pdfOrder.value).toPdf().get('pdf').save()
}

</script>
<style lang="sass">
    .pdf-box
        display: none
    .pdf-break
        break-after: page
        height: 0
        display: block
        pointer-events: none
    .pdf-button
        color: var(--white)
        background-color: var(--gold)
        padding: 10px 20px
        border-radius: 3px
        font-family: 'Roboto'
        font-weight: 400
        font-size: 14px
        display: flex
        justify-content: center
        align-items: center
        gap: 10px
        transition: .3s ease-in-out
        transition-property: background-color
        &:hover
            @media (min-width: 540px)
                background-color: var(--gray-dark)
    .order-pdf
        width: 1920px
        padding: 10px
        background-color: var(--white)
    .pdf-header
        width: 100%
        display: grid
        grid-template-columns: repeat(2, 1fr)
        &__logo-container
            display: flex
            &__logo
                width: 265px
                display: block
            &__order-number
                font-size: 50px
                margin-left: 50px
                position: relative
                bottom: 8px
        &__text
            display: block
            color: var(--gray-dark)
            font-family: 'Roboto'
            font-weight: 400
            font-size: 32px
            margin-left: auto
            b
                font-weight: 500
                margin-right: 20px
    .pdf-main
        padding: 54px 0
    .pdf-col
        width: 100%
    .pdf-hero
        width: 100%
        display: grid
        grid-template-columns: repeat(2, 1fr)
        &__title
            color: var(--gray-dark)
            display: block
            font-family: 'Roboto'
            font-weight: 500
            font-size: 40px
        &__date
            display: block
            color: var(--gray-dark)
            font-family: 'Roboto'
            font-weight: 400
            font-size: 32px
            text-align: right
            &:not(:first-child)
                margin-top: 20px
            b
                font-weight: 500
                margin-right: 20px
    .pdf-information
        width: 100%
        display: grid
        grid-template-columns: repeat(2, 1fr)
        margin-top: 40px
        page-break-inside: avoid
        &__item
            display: flex
            &:not(:first-child)
                margin-top: 20px
        &_right
            margin-left: auto
        &__title
            max-width: 300px
            width: 100%
            display: block
            font-family: 'Roboto'
            font-weight: 500
            font-size: 32px
            margin-right: 18px
            color: var(--gray-dark)
        &__text
            display: block
            color: var(--gray-dark)
            font-family: 'Roboto'
            font-weight: 400
            font-size: 32px
    .pdf-service
        width: 100%
        overflow: auto
        margin-top: 40px
        page-break-inside: avoid
    .pdf-table
        width: 1700px
        border-collapse: collapse
        border: 1px solid var(--gray)
        &__title
            padding: 12px 32px
            color: var(--gray-dark)
            font-family: 'Roboto'
            font-weight: 500
            font-size: 22px
            border: 1px solid var(--gray)
            text-align: left
            &_7
                width: 7%
            &_33
                width: 33%
            &_15
                width: 15%
        &__text
            padding: 12px 32px
            color: var(--gray-dark)
            font-family: 'Roboto'
            font-weight: 400
            font-size: 22px
            border: 1px solid var(--gray)
    .pdf-description
        width: 100%
        display: grid
        grid-template-columns: repeat(2, 1fr)
        gap: 30px
        margin-top: 40px
        page-break-inside: avoid
        &__title
            color: var(--gray-dark)
            font-family: 'Roboto'
            font-weight: 500
            font-size: 32px
            display: block
        &__text
            font-family: 'Roboto'
            font-weight: 400
            font-size: 22px
            color: var(--gray-dark)
            margin-top: 32px
    .pdf-payment
        width: 100%
        display: grid
        grid-template-columns: repeat(2, 1fr)
        gap: 30px
        margin-top: 80px
        page-break-inside: avoid
        &__item
            display: flex
            &:not(:first-child)
                margin-top: 20px
        &__title
            width: 100%
            display: block
            font-family: 'Roboto'
            font-weight: 500
            font-size: 32px
            color: var(--gray-dark)
            &_min
                margin-right: 20px
                max-width: 400px
        &__text
            display: block
            color: var(--gray-dark)
            font-family: 'Roboto'
            font-weight: 400
            font-size: 22px
        &__number
            display: block
            color: var(--gray-dark)
            font-family: 'Roboto'
            font-weight: 400
            font-size: 32px
            &_gold
                color: var(--gold)
        &__image
            margin-top: 10px
    .pdf-footer
        margin-top: 10px
        width: 100%
        &__text
            font-family: 'Roboto'
            font-weight: 400
            font-size: 22px
            color: var(--gray-dark)
            &:not(:first-child)
                margin-top: 20px
</style>