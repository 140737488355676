<template>
    <div class="settings">
        <h3 class="settings__title">Безопасность</h3>
        <ul class="settings__list">
            <li class="settings__item">
                <div class="settings__text-box">
                    <span class="settings__text">Email</span>
                    <span class="settings__text_gray">testmail@mail.ru</span>
                </div>
                <button-component class="settings__button" buttonText="сменить" @click="openLoginPopup = true" />
            </li>
            <li class="settings__item">
                <div class="settings__text-box">
                    <span class="settings__text">Пароль</span>
                    <span class="settings__text_gray">********</span>
                </div>
                <button-component class="settings__button" buttonText="сменить" @click="openPasswordPopup = true" />
            </li>
        </ul>
        <button-component buttonText="Выйти из профиля" iconName="logout" iconSize="24" class="logout" @click="logout"/>
        <popup-component :mt="true" :isOpen="openPasswordPopup" @close="openPasswordPopup = false">
            <template #title>
                Изменение пароля
            </template>
            <template #content>
                <form method="post" class="settings__form">
                    <input-component class="settings__input" v-model:meaning="passwordForm.password" @input="errorsPassword.password = ''" inputType="password" inputName="oldPassword" inputLabel="Введите текущий пароль" inputContent="********" :inputError="errorsPassword.password"/>
                    <input-component class="settings__input" v-model:meaning="passwordForm.newPassword" @input="errorsPassword.newPassword = ''" inputType="password" inputName="Newpassword" inputLabel="Введите новый пароль" inputContent="********" :inputError="errorsPassword.newPassword"/>
                    <input-component class="settings__input" v-model:meaning="confirmedPassword" inputType="password" inputName="confirmedPassword" inputLabel="Повторите новый пароль" inputContent="********" :inputError="confirmed"/>
                    <button-component class="settings__submit" :class="{ settings__submit_disabled: disabled }" buttonText="Сменить пароль" :disabled="disabled" @click.prevent="updatePassword"/>
                </form>
            </template>
        </popup-component>
        <popup-component :mt="true" :isOpen="openLoginPopup" @close="openLoginPopup = false">
            <template #title>
                Изменение почты
            </template>
            <template #content>
                <form method="post" class="settings__form">
                    <input-component class="settings__input" v-model:meaning="emailForm.password" @input="errorsEmail.password = ''" inputType="password" inputName="пароль" inputLabel="Введите пароль" inputContent="********" :inputError="errorsEmail.password"/>
                    <input-component class="settings__input" v-model:meaning="emailForm.email" @input="errorsEmail.email = ''" inputType="email" inputName="электронная почта" inputLabel="Введите новую почту" inputContent="testmail@mail.com" :inputError="errorsEmail.email"/>
                    <button-component class="settings__submit" buttonText="Сменить логин" @click.prevent="updateEmail"/>
                </form>
            </template>
        </popup-component>
    </div>
</template>
<script setup lang="ts">
import buttonComponent from '../shared/buttonComponent.vue';
import InputComponent from '../shared/Form/Input/InputComponent.vue';
import PopupComponent from '../entities/popupComponent.vue';
import axios from '../app/lib/api';
import router from '../app/router/router';
import { ref, watch } from 'vue';
import { updateEmailShema, updatePasswordShema } from '../app/lib/shemas/updateUserShema';
import toast from '../app/lib/hooks/errorToast';
import {useLoadingStore} from '../app/store/store';

const loading = useLoadingStore()
const openPasswordPopup = ref(false)
const openLoginPopup = ref(false)
const confirmed = ref("")
const disabled = ref(true)

const passwordForm = ref({
    password: "",
    newPassword: "",
})
const errorsPassword= ref({
    password: "",
    newPassword: "",
})
const confirmedPassword = ref("")


const emailForm = ref({
    email: "",
    password: "",
})
const errorsEmail = ref({
    email: "",
    password: "",
})

async function logout() {
    try {
        await axios.auth.logout()
        document.cookie = "access_token=;max-age=-1;path=/";
        localStorage.removeItem("roles")
        router.push({name: 'login'})
    } catch (error) {
    }
}

async function updatePassword() {
    try {
        await updatePasswordShema.validate(passwordForm.value, { abortEarly: false })
        errorsPassword.value = {
            password: "",
            newPassword: "",
        }
        try {
            loading.loading()
            const res = await axios.auth.updatePassword(passwordForm.value)
            loading.load()
            toast("success", res.data.message)
        } catch (error: any) {
            loading.load()
            toast("error", error.response.data.message)
            error.response.data.errors?.forEach((item: any) => toast("error", item.message))
        }
    } catch (error: any) {
        // @ts-ignore
        error.inner.forEach((error) => {
            // @ts-ignore
            errorsPassword.value[error.path] = error.message;
        });
    }
}

async function updateEmail() {
    try {
        await updateEmailShema.validate(emailForm.value, { abortEarly: false })
        errorsEmail.value = {
            email: "",
            password: "",
        }
        try {
            loading.loading()
            const res = await axios.auth.updateEmail(emailForm.value)
            loading.load()
            toast("success", res.data.message)
        } catch (error: any) {
            loading.load()
            toast("error", error.response.data.message)
            error.response.data.errors?.forEach((item: any) => toast("error", item.message))
        }
    } catch (error: any) {
        // @ts-ignore
        error.inner.forEach((error) => {
            // @ts-ignore
            errorsEmail.value[error.path] = error.message;
        });
    }
}

watch(() => [confirmedPassword.value, passwordForm.value.newPassword], () => {
    if(confirmedPassword.value === passwordForm.value.newPassword) {
        confirmed.value = ""
        disabled.value = false
    } else {
        confirmed.value = "Пароли должны совпадать"
        disabled.value = true
    }
})

</script>
<style lang="sass">
    .settings
        max-width: 906px
        width: 100%
        display: block
        &__title
            display: block
            width: 100%
            padding: 22px 30px
            background: var(--brown)
            font-family: 'Roboto'
            font-weight: 500
            font-size: 22px
            color: var(--white)
            border-radius: 5px 5px 0 0
        &__list
            background-color: var(--gray-light)
            padding: 0 30px
            width: 100%
            border-radius: 0 0 5px 5px
        &__item
            display: flex
            align-items: center
            height: 80px
            &:not(:last-child)
                border-bottom: 1px solid var(--gray-prelight)
            @media (max-width: 1030px)
                height: auto
                padding: 10px
            @media (max-width: 460px)
                flex-direction: column
                align-items: stretch
                gap: 30px
        &__text
            font-family: 'Roboto'
            font-weight: 600
            font-size: 18px
            color: var(--brown)
            width: 63px
            &_gray
                width: auto
                font-weight: 400
                color: var(--gray)
            &-box
                display: flex
                align-items: center
                gap: 230px
                @media (max-width: 1030px)
                    flex-direction: column
                    gap: 30px
                    align-items: stretch
        &__button
            background-color: var(--brown)
            font-size: 14px
            color: var(--white)
            padding: 11px 27px
            margin-left: auto
            transition: .3s ease-in-out
            transition-property: background-color
            &:hover
                @media (min-width: 540px)
                    background-color: var(--gold)
            @media (max-width: 460px)
                margin-left: 0
        &__form
            max-width: 400px
            display: flex
            flex-direction: column
            gap: 20px
        &__submit
            pointer-events: auto
            background-color: var(--gold)
            color: var(--white)
            padding: 15px 20px
            width: 100%
            transition: .3s ease-in-out
            transition-property: background-color
            &:hover
                @media (min-width: 540px)
                    background-color: var(--brown)
            &_disabled
                pointer-events: none
                background-color: var(--gray)
    .logout
        gap: 10px
        color: var(--gold)
        margin-top: 60px
        font-weight: 400
        display: flex
        align-items: center
        &:hover
            @media (min-width: 540px)
                text-decoration: underline
</style>