import {object, array, string, number, boolean, InferType, date, mixed} from "yup"

export const updateOrderShema = object({
    information: object({
        client: string().required('Введите ФИО заказчика'),
        clientEmail: string(),
        clientPhone: string().required('Введите номер телефона'),
        clientAddress: string(),
        deceased: string().required('Введите ФИО покойника'),
        deathDate: mixed(),
        cemetery: string().required('укажите место установки'),
        graveDistrict: string().required('Введите участок'),
        graveRow: string().required('Введите Ряд'),
        gravePlace: string().required('Введите место'),
    }),
    services: array(
        object({
            title: string().required('Выберите услугу'),
            measurement: string(),
            quantity: number(),
            cost: number(),
            price: number(),
        })
    ),
    moreServices: string(),
    price: object({
        total: number(),
        discountValue: number(),
        discountMeasure: string(),
        discount: number(),
        final: number(),
    }),
    dates: object({
        startAt: date().required('Укажите дату начала работ'),
        endAt: date().required('Укажите дату конца работ'),
    }),
    comment: string(),
    uploadImage: string(),
    costs: object({
        concrete: number().required('ошибка заполнения'),
        concreteValue: number().required('ошибка заполнения'),
        concreteMeasure: string().required('ошибка заполнения'),
        materials: number().required('ошибка заполнения'),
        materialsValue: number().required('ошибка заполнения'),
        materialsMeasure: string().required('ошибка заполнения'),
        departure: number().required('ошибка заполнения'),
        departureValue: number().required('ошибка заполнения'),
        departureMeasure: string().required('ошибка заполнения'),
        brigade: number().required('ошибка заполнения'),
        brigadeValue: number().required('ошибка заполнения'),
        brigadeMeasure: string().required('ошибка заполнения'),
        raw: number().required('ошибка заполнения'),
        rawValue: number().required('ошибка заполнения'),
        rawMeasure: string().required('ошибка заполнения'),
        install: number().required('ошибка заполнения'),
        installValue: number().required('ошибка заполнения'),
        installMeasure: string().required('ошибка заполнения'),
    })
})


export type updateOrderType = InferType<typeof updateOrderShema>


export const orderPaymentShema =  object({
    date: date(),
    amount: number().moreThan(0, 'Введите сумму'),
    method: string().required('Укажите способ оплаты'),
})

export type orderPaymentType = InferType<typeof orderPaymentShema>