<template >
    <div class="order">
        <div class="order__content" v-if="bool">
            <order-information-component :order="staticOrder" v-if="!setOrder"/>
            <order-update-component :order="order" v-if="setOrder && role !== UserRole.USER" @updateOrder="updateOrder"/>
        </div>
        <div class="order__editing-box" v-if="staticOrder.status !== OrderStatus.archive">
            <button-component class="order__editing" button-text="смотреть заказ" v-if="setOrder && role !== UserRole.USER" iconName="paper" @click="setEditing"/>
            <button-component class="order__editing" button-text="редактировать заказ" v-if="!setOrder && role !== UserRole.USER && role !== UserRole.MANAGER" iconName="pancil" @click="setEditing"/>
            <button-component class="order__editing" button-text="Поля Бухгалтерии" v-if="!setOrder && role !== UserRole.USER && role !== UserRole.MANAGER" iconName="pancil" @click="openCosts = true"/>
            <order-file-component v-if="bool && !setOrder" :order="order" class="order__editing_pdf"/>
        </div>
        <popup-component :mt="true" :isOpen="openCosts === true" @close="openCosts = false">
            <template #title>
                Дополнительные поля
            </template>
            <template #content>
                <update-cost :data="staticOrder.costs" :id="id" :price="staticOrder.price.final" @close="(data: any)=> { openCosts = data.data }" @costs="(data: any) => {updateCosts(data.data)}"/>
            </template>
        </popup-component>
    </div>
</template>
<script setup lang="ts">
import orderInformationComponent from '../components/orderInformationComponent.vue';
import orderUpdateComponent from '../components/orderUpdateComponent.vue';
import ButtonComponent from '../shared/buttonComponent.vue';
import PopupComponent from '../entities/popupComponent.vue';
import { UpdateCost } from '../widgets/Cost/OrderCosts';
import axios from '../app/lib/api';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { Order, OrderStatus } from '../app/lib/types/orders';
import toast from '../app/lib/hooks/errorToast';
import orderFileComponent from '../components/orderFileComponent.vue';
import { UserRole } from '../app/lib/types/users';
import _ from 'lodash';

const router = useRoute()
const setOrder = ref(false)
let bool = ref(false)
let staticOrder: Order = {} as Order
let order: Order = {} as Order
const role = localStorage.getItem("role")
const openCosts = ref(false)
const id = router.params.orderId as string

function setEditing() {
    if(role !==  UserRole.USER) {
        setOrder.value = !setOrder.value
    } else {
        setOrder.value = false
    }
}

function updateOrder(data: any) {
    staticOrder = {...staticOrder, ...data.updateOrderFields._value}
    setOrder.value = false
}
function updateCosts(data: any) {
    staticOrder = {...staticOrder, ...data}
}

onMounted(() => {
    axios.order.getOrder(router.params.orderId as string).then((res: any) => {
        staticOrder = res.data
        order = _.cloneDeep(staticOrder)
        bool.value = true
    }).catch((err: any) => {
        toast('error', err.response.data.message)
    })
})

</script>
<style lang="sass">
    .order
        position: relative
        &__editing
            color: var(--white)
            background-color: var(--gold)
            padding: 10px 20px
            border-radius: 3px
            font-family: 'Roboto'
            font-weight: 400
            font-size: 14px
            display: flex
            justify-content: center
            align-items: center
            gap: 10px
            transition: .3s ease-in-out
            transition-property: background-color
            &:not(:first-child)
                margin-top: 10px
            &:hover
                @media (min-width: 540px)
                    background-color: var(--gray-dark)
            &-box
                position: fixed
                z-index: 2
                bottom: 20px
                right: 30px
            &_pdf
                margin-top: 10px
</style>