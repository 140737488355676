<template>
    <div class="profile">
        <div class="profile__image-box">
            <img :src="image.avatar" alt="avatar" class="profile__image">
            <span class="profile__name" v-if="contacts">{{contacts.fullname}}</span>
            <span class="profile__role" v-if="role">{{role}}</span>
        </div>
        <div class="profile__information-box" v-if="contacts">
            <ul class="profile__information">
                <li class="profile__item">
                    <span class="profile__text">Полное имя</span>
                    <form method="post" class="profile__form" v-if="editing === 'fullname'">
                        <input class="profile__input" v-model="fullname">
                        <div class="profile__edit-box">
                            <button-component class="profile__edit" iconName="success" iconSize="32" @click.prevent="updateFullnameHandler"/>
                            <button-component class="profile__edit" iconName="closeBig" iconSize="32" @click="toggleEditing(null)" />
                        </div>
                    </form>
                    <div class="profile__text-box" v-else >
                        <span class="profile__text profile__text_gray">{{contacts.fullname}}</span>
                        <button-component class="profile__editing" iconName="pancil" iconSize="20" @click="toggleEditing('fullname')" />
                    </div>
                </li>
                <li class="profile__item">
                    <span class="profile__text">Email для связи</span>
                    <form method="post" class="profile__form" v-if="editing === 'email'">
                        <input class="profile__input" v-model="email">
                        <div class="profile__edit-box">
                            <button-component class="profile__edit" iconName="success" iconSize="32" @click.prevent="updateEmailHandler"/>
                            <button-component class="profile__edit" iconName="closeBig" iconSize="32" @click="toggleEditing(null)" />
                        </div>
                    </form>
                    <div class="profile__text-box" v-else >
                        <span class="profile__text profile__text_gray">{{contacts.email}}</span>
                        <button-component class="profile__editing" iconName="pancil" iconSize="20" @click="toggleEditing('email')" />
                    </div>
                </li>
                <li class="profile__item">
                    <span class="profile__text">Номер телефона</span>
                    <form method="post" class="profile__form" v-if="editing === 'phone'">
                        <input class="profile__input" v-model="phone">
                        <div class="profile__edit-box">
                            <button-component class="profile__edit" iconName="success" iconSize="32" @click.prevent="updatePhoneHandler"/>
                            <button-component class="profile__edit" iconName="closeBig" iconSize="32" @click="toggleEditing(null)" />
                        </div>
                    </form>
                    <div class="profile__text-box" v-else >
                        <span class="profile__text profile__text_gray">{{contacts.phone}}</span>
                        <button-component class="profile__editing" iconName="pancil" iconSize="20" @click="toggleEditing('phone')" />
                    </div>
                </li>
                <li class="profile__item">
                    <span class="profile__text">Дата рождения</span>
                    <form method="post" class="profile__form" v-if="editing === 'birthday'">
                        <input class="profile__input" v-model="birthday">
                        <div class="profile__edit-box">
                            <button-component class="profile__edit" iconName="success" iconSize="32" @click.prevent="updateBirthdayHandler"/>
                            <button-component class="profile__edit" iconName="closeBig" iconSize="32" @click="toggleEditing(null)" />
                        </div>
                    </form>
                    <div class="profile__text-box" v-else >
                        <span class="profile__text profile__text_gray">{{contacts.birthday}}</span>
                        <button-component class="profile__editing" iconName="pancil" iconSize="20" @click="toggleEditing('birthday')" />
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script setup lang="ts">
import buttonComponent from '../shared/buttonComponent.vue';
import axios from '../app/lib/api';
import { Contacts, Field } from '../app/lib/types/users';
import avatar from '../assets/img/avatar.png'
import { onMounted, Ref, ref } from 'vue';
import { updateContactsBirthdayShema, updateContactsEmailShema, updateContactsFullnameShema, updateContactsPhoneShema, updateContactsType } from '../app/lib/shemas/updateUserShema';
import toast from '../app/lib/hooks/errorToast';
import {useLoadingStore} from '../app/store/store';

const image = {
    avatar: avatar
}
let staticContacts = {} as Contacts
let contacts = ref({} as Contacts)
const role = localStorage.getItem("role")
const fullname = ref("")
const email = ref("")
const phone = ref("")
const birthday = ref("")
const editing: Ref<Field | null> = ref(null)
const loading = useLoadingStore()


function toggleEditing(item: Field | null) {
    editing.value = item
    fullname.value = contacts.value.fullname
    email.value = contacts.value.email
    phone.value = contacts.value.phone
    birthday.value = contacts.value.birthday
}

async function updateFullnameHandler(){
    try {
        await updateContactsFullnameShema.validate(fullname.value, { abortEarly: false })
        try {
            loading.loading()
            const res = await axios.users.updateUserInfo({ fullname: fullname.value } as updateContactsType)
            contacts.value.fullname = fullname.value
            editing.value = null
            loading.load()
            toast("success", res.data.message)
        } catch (error: any) {
            loading.load()
            toast("error", error.response.data.message)
        }
    } catch (error: any) {
        toast("error", error.message)
    }
}

async function updateEmailHandler(){
    try {
        await updateContactsEmailShema.validate(email.value, { abortEarly: false })
        try {
            loading.loading()
            const res = await axios.users.updateUserInfo({ email: email.value } as updateContactsType)
            contacts.value.email = email.value
            editing.value = null
            loading.load()
            toast("success", res.data.message)
        } catch (error: any) {
            loading.load()
            toast("error", error.response.data.message)
        }
    } catch (error: any) {
        toast("error", error.message)
    }

}

async function updatePhoneHandler(){
    try {
        await updateContactsPhoneShema.validate(phone.value, { abortEarly: false })
        try {
            loading.loading()
            const res = await axios.users.updateUserInfo({ phone: phone.value } as updateContactsType)
            contacts.value.phone = phone.value
            editing.value = null
            loading.load()
            toast("success", res.data.message)
        } catch (error: any) {
            loading.load()
            toast("error", error.response.data.message)
        }
    } catch (error: any) {
        toast("error", error.message)
    }

}

async function updateBirthdayHandler(){
    try {
        await updateContactsBirthdayShema.validate(birthday.value, { abortEarly: false })
        try {
            loading.loading()
            const res = await axios.users.updateUserInfo({ birthday: birthday.value } as updateContactsType)
            contacts.value.birthday = birthday.value
            editing.value = null
            loading.load()
            toast("success", res.data.message)
        } catch (error: any) {
            loading.load()
            toast("error", error.response.data.message)
        }
    } catch (error: any) {
        toast("error", error.message)
    }

}



onMounted(async () => {
    loading.loading()
    axios.users.getUserInfo()
        .then((res: any) => {
            contacts.value = res.data
            fullname.value = staticContacts.fullname
            email.value = staticContacts.email
            phone.value = staticContacts.phone
            birthday.value = staticContacts.birthday
            loading.load()
        })
        .catch((err: any) => {
            loading.load()
        })
} )


</script>
<style lang="sass">
    .profile
        display: grid
        grid-template-columns: 386px 9fr
        gap: 19px
        @media (max-width: 1320px)
            grid-template-columns: 100%
        &__image
            display: block
            width: 180px
            height: 180px
            background-color: var(--white)
            border-radius: 50%
            &-box
                padding: 20px 30px
                background-color: var(--gray-light)
                width: 100%
                display: flex
                flex-direction: column
                align-items: center
        &__information
            padding: 0 30px
            background-color: var(--gray-light)
            width: 100%
        &__item
            height: 80px
            width: 100%
            display: flex
            align-items: center
            gap: 80px
            &:not(:last-child)
                border-bottom: 1px solid var(--gray-prelight)
            @media (max-width: 900px)
                flex-direction: column
                align-items: stretch
                gap: 30px
                padding: 20px
                height: auto
        &__text
            display: block
            font-family: 'Roboto'
            font-weight: 600
            font-size: 18px
            color: var(--brown)
            max-width: 143px
            width: 100%
            &_gray
                color: var(--gray)
                padding: 11px
                max-width: 420px
                width: 100%
            &-box
                display: flex
                align-items: center
                width: 100%
        &__editing
            display: flex
            align-items: center
            justify-content: center
            margin-left: auto
            width: 32px
            height: 32px
            border-radius: 50%
            background-color: var(--brown)
            color: var(--white)
            flex: none
            transition: .3s ease-in-out
            transition-property: background-color
            &:hover
                @media (min-width: 540px)
                    background-color: var(--gold)
        &__edit
            display: block
            width: 32px
            height: 32px
            color: var(--brown)
            transition: .3s ease-in-out
            transition-property: color
            &:hover
                @media (min-width: 540px)
                    color: var(--gold)
            &-box
                margin-left: auto
                display: flex
                align-items: center
                gap: 10px
            &:hover
                @media (min-width: 540px)
        &__form
            display: flex
            align-items: center
            width: 100%
        &__input
            background: none
            border: 1px solid var(--gray)
            border-radius: 3px
            padding: 10px
            width: 300px
            font-family: 'Roboto'
            font-weight: 600
            font-size: 18px
            color: var(--brown)
        &__name
            display: block
            width: 100%
            font-family: 'Roboto'
            font-weight: 500
            font-size: 20px
            text-align: center
            padding-top: 20px
            color: var(--brown)
        &__role
            padding-top: 10px
            font-family: 'Roboto'
            font-weight: 400
            font-size: 16px
            text-align: center
            color: var(--gray)
</style>