<template>
    <div class="page page-404">
        <header-component @openMenu="openMenu" :menuOpen="menu"/>
        <main class="main">
            <div class="content">
                <h1>404</h1>
                <div class="info">Страница не найдена</div>
                <router-view></router-view>
            </div>
        </main>
    </div>
</template>

<script setup lang="ts">
    import headerComponent from "../components/headerComponent.vue";
    import { ref } from "vue";

    let menu = ref(false)
    const openMenu = () => menu.value = !menu.value
</script>

<style lang="sass">
    .page-404
        .content
            text-align: center
            font-size: 80px
</style>