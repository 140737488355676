<template >
    <div class="login__container">
        <img :src="images.logo" alt="Март" class="login__logo">
        <h2 class="login__title">Вход в приложение</h2>
        <form @submit.prevent="submit" action="" method="post" class="login__form">
            <input-component v-model:meaning="values.identity" @input="errors.identity= ''" inputType="text" inputName="login" inputContent="admin" inputLabel="Логин или email" :inputError="errors.identity" />
            <input-component v-model:meaning="values.password" @input="errors.password= ''"  inputType="password" inputName="password" inputAutocomplete="on" inputContent="********" inputLabel="Пароль" :inputError="errors.password" />
            <!-- <v-select v-model="citiesList.name" placeholder="Выберите город" :options="citiesList" label="name" :reduce="citiesList => citiesList.region" >
                <template #search="{attributes, events}">
                    <input
                        class="vs__search"
                        :required="!citiesList"
                        v-bind="attributes"
                        v-on="events"
                    />
                </template>
            </v-select> -->
            <select v-model="values.regionId" name="regionId" required @change="getCityIndex($event)">
                <option value="" disabled selected>Выберите город</option>
                <option v-for="city in citiesList" :value="city._id" :key="city._id"  :data-city-index="city.index">{{ city.name }}</option>
            </select>
            <input type="hidden" name="currentRegionIndex" value="">
            <label class="login__save-box">
                <input v-model="cheked" type="checkbox" class="login__save">
                <span class="login__save-text">Запомнить меня</span>
            </label>
            <div class="login__button-box">
                <button-component buttonText="Начать работу" class="login__button" />
                <router-link :to="{name:'restore'}" class="login__link">Забыли пароль?</router-link>
            </div>
        </form>
        <router-link v-if="onlineStore.isOnline === false" :to="{name:'createOrder'}" class="login__link">создать заказ</router-link>
    </div>
</template>
<script setup lang="ts">

import logo from '../assets/img/svg/logo.svg';
import InputComponent from '../shared/Form/Input/InputComponent.vue';
import buttonComponent from '../shared/buttonComponent.vue';
import * as yup from "yup"
import toast from '../app/lib/hooks/errorToast'
import axios from '../app/lib/api'
import { onMounted, ref } from "vue"
import router from '../app/router/router';
import {useLoadingStore, useOnlineStore } from '../app/store/store';
import { watch } from 'fs';
import {Cities} from '../app/lib/types/cities'

const citiesList = ref([] as unknown as Cities[])
const loading = useLoadingStore()
const onlineStore = useOnlineStore()

const images = {
    logo: logo
}

const values = ref({
  identity: "",
  password: "",
  regionId: "",
  currentRegionIndex: ""
})

const errors = ref({
  identity: "",
  password: "",
  regionId: ""
})

const loginShema = yup.object({
  identity: yup.string().required('Введите логин'),
  password: yup.string().required('Введите пароль'),
  regionId: yup.string().required('Выберите город'),
})

let cheked = ref(false)

const login = async () => {
    try {
        const res = await axios.auth.login(values.value)
        if(cheked.value == true) {
            document.cookie = `access_token=${res.data.accessToken}; path=/`
        } else {
            document.cookie = `access_token=${res.data.accessToken}; path=/`
        }
        localStorage.setItem("role", res.data.role)
        localStorage.setItem("regionId", res.data.region)
        router.push({name: 'main'})
        toast('success', "Вы успешно авторизовались")
    }
    catch (err: any) {
        toast('error', err.response.data.message)
    }
}

onMounted(() => {
    axios.cities.getCitiesList()
    .then((r: any) => {
        citiesList.value = r.data
    })
    .catch((err: any) => {
        loading.load()
        toast('error', err + ' Не удалось получить список городов')
    })
})

/**
 * Получение индекса города, для дальнейшей работы с ним после аутентификации.
 */
function getCityIndex(e: any) {
    values.value.currentRegionIndex = e.target.options[e.target.options.selectedIndex].getAttribute('data-city-index')
}

/**
 * Отправка формы.
 * 
 * @param e 
 */
async function submit(e: any) {
    try {
        await loginShema.validate(values.value, { abortEarly: false })
        errors.value = {
            identity: "",
            password: "",
            regionId: ""
        }
        return await login()
    }
    catch(err: any) {
        // @ts-ignore
        err.inner.forEach((error) => {
        // @ts-ignore
        errors.value[error.path] = error.message;
        });
    }
}
</script>

<style lang="sass">
.login
    &__container
        max-width: 426px
        width: 100%
        margin: 0 auto
        padding: 0 20px
    &__logo
        width: 230px
        margin: 0 auto
    &__title
        margin-top: 100px
        color: var(--brown)
        font-family: 'Roboto'
        font-weight: 500
        font-size: 24px
        text-align: center
    &__form
        margin-top: 30px
        width: 100%
        display: flex
        flex-direction: column
        gap: 30px
    &__save
        display: none
        &:checked ~ .login__save-text::before
            background: url(../assets/img/svg/icon/cheked.svg) no-repeat center center
            border-color: var(--gold)
        &-box
            display: flex
            align-items: center
        &-text
            display: block
            color: var(--brown)
            font-family: 'Roboto'
            font-weight: 400
            font-size: 18px
            display: flex
            align-items: center
            &::before
                display: block
                content: ''
                width: 18px
                height: 18px
                border: 2px solid var(--brown)
                border-radius: 3px
                margin-right: 10px
    &__button
        width: 100%
        text-align: center
        padding: 14px 20px
        background-color: var(--gold)
        color: var(--white)
        text-transform: uppercase
    &__link
        display: block
        margin-top: 15px
        width: 100%
        text-align: center
        font-family: 'Roboto'
        font-weight: 400
        font-size: 18px
        padding: 14px 20px
        border: 1px solid var(--gray)
        border-radius: 3px
        color: var(--gray)
select
    background: var(--gray-light)
    border-radius: 3px
    padding: 14px
    font-family: "Roboto"
    font-size: 14px
    cursor: pointer
    border: none
    color: var(--gray-dark)
    > option
        padding: 10px
        font-size: 20px
    > option[disabled]
        color: #ada9a9
        font-size: 14px
</style>