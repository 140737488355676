<template >
    <div page-card>
        <h2 class="create-order__title">Наряд-заказ на благоустройство могилы</h2>
        <span class="create-order__required">* — поля, обязательные для заполнения</span>
        <form action="" type="POST" class="create-order__form">
            <div class="order-information__item information">
                <h3 class="order-information__title_min">Даты заказа</h3>
                <div class="information__list">
                    <div class="information__item">
                        <span class="information__title">Дата заключения договора</span>
                        <Datepicker :disabled="disabled" class="create-order__date-item" auto-apply format="dd/MM/yyyy" v-model="updateOrderFields.createdAt" :enable-time-picker="false" @update:model-value="changeOrderCreatedAt" locale="ru">
                            <template #calendar-header="{ index, day }">
                                <div class="date__day" :class="index === 5 || index === 6 ? 'gold-color' : ''">
                                    {{ day }}
                                </div>
                            </template>
                        </Datepicker>
                    </div>
                    <div class="information__item">
                        <span class="information__title">Дата закрытия договора</span>
                        <Datepicker :disabled="disabled" class="create-order__date-item" auto-apply format="dd/MM/yyyy" v-model="updateOrderFields.closedAt" :enable-time-picker="false" @update:model-value="changeOrderClosedAt" locale="ru">
                            <template #calendar-header="{ index, day }">
                                <div class="date__day" :class="index === 5 || index === 6 ? 'gold-color' : ''">
                                    {{ day }}
                                </div>
                            </template>
                        </Datepicker>
                    </div>
                </div>
            </div>
            <div class="create-order__item information" >
                <h3 class="create-order__title_min">Контактная информация</h3>
                <div class="information__list">
                    <input-component :disabled="disabled" v-model:meaning="updateOrderFields.information.client" @input="errors.informationclient= ''" inputType="text" inputName="customer" inputContent="Иванов Иван Иванович" inputLabel="Ф.И.О. заказчика" :required=true :inputError="errors.informationclient" />
                    <input-component :disabled="disabled" v-model:meaning="updateOrderFields.information.clientPhone" @input="errors.informationclientPhone= ''" inputType="tel" inputName="phone" inputContent="+7 (999) 999-99-99" inputLabel="Номер телефона" :required=true :inputError="errors.informationclientPhone" />
                    <input-component :disabled="disabled" v-model:meaning="updateOrderFields.information.clientAddress" inputType="text" inputName="adress" inputContent="г. Великий Новгород, ул. Луговая, 7" inputLabel="Адрес" />
                    <input-component :disabled="disabled" v-model:meaning="updateOrderFields.information.clientEmail" inputType="email" inputName="email" inputContent="email@mail.ru" inputLabel="Email"/>
                    <input-component :disabled="disabled" v-model:meaning="updateOrderFields.information.deceased" @input="errors.informationdeceased= ''" inputType="text" inputName="deceased" inputContent="Петров Пётр Петрович" inputLabel="Ф.И.О. покойного" :required=true :inputError="errors.informationdeceased" />
                    <VDropdown
                    :distance="6"
                    :disabled="disabled || updateOrderFields.information.cemetery != ''"
                    @click="errors.informationcemetery= ''"
                    >
                    <input-component :disabled="disabled" v-model:meaning="updateOrderFields.information.cemetery" @input="errors.informationcemetery= ''" inputType="text" inputName="cemetery" inputContent="Укажите/введите кладбище" inputLabel="Место установки" :required=true :inputError="errors.informationcemetery" />
                        <template #popper>
                            <div class="information__select">
                                <button class="information__select-item" v-for="item in cityData.params.cemetery" @click="setCemetry(item.name)">{{item.name}}</button>
                            </div>
                        </template>
                    </VDropdown>
                    <div class="information__date-box">
                        <span class="information__date-title">Дата смерти</span>
                        <Datepicker :disabled="disabled" class="information__date" placeholder="Укажите дату" auto-apply format="dd/MM/yyyy" v-model="updateOrderFields.information.deathDate" :enable-time-picker="false" locale="ru">
                            <template #calendar-header="{ index, day }">
                                <div class="date__day" :class="index === 5 || index === 6 ? 'gold-color' : ''">
                                  {{ day }}
                                </div>
                              </template>
                        </Datepicker>
                    </div>
                    <div class="information__list_sub">
                        <input-component :disabled="disabled" v-model:meaning="updateOrderFields.information.graveDistrict" @input="errors.informationgraveDistrict= ''" inputType="text" inputName="district" inputContent="№ квадрата" inputLabel="Квадрат" :required=true :inputError="errors.informationgraveDistrict" />
                        <input-component :disabled="disabled" v-model:meaning="updateOrderFields.information.graveRow" @input="errors.informationgraveRow= ''" inputType="text" inputName="row" inputContent="№ ряда" inputLabel="Ряд" :required=true :inputError="errors.informationgraveRow" />
                        <input-component :disabled="disabled" v-model:meaning="updateOrderFields.information.gravePlace" @input="errors.informationgravePlace= ''" inputType="text" inputName="place" inputContent="№ места" inputLabel="Место" :required=true :inputError="errors.informationgravePlace" />
                    </div>
                </div>
            </div>
            <div class="create-order__item service">
                <h3 class="create-order__title_min">Список оказываемых услуг</h3>
                <div class="service__list">
                    <div class="service__item" v-for="service, index in updateOrderFields.services" :key="service.title">
                        <buttonComponent class="service__delete" buttonText="Удалить услугу" :reverseIcon=true iconSize="20" iconName="delete" @click.prevent="removeOrderService(service.title)" />
                        <span class="service__index">{{index + 1}}</span>
                        <span class="service__title">Наименование услуги</span>
                        <v-select :disabled="disabled" v-model="service.title" placeholder="Ограда" :options="serviceNameList" @option:selected="setMeasurement(service)" />
                        <div class="service__parametrs">
                            <input-component :disabled="disabled" v-model:meaning="service.quantity" @input="service.price =service.cost * service.quantity" inputType="number" inputName="quantity" inputContent="100" inputLabel="Размер"/>
                            <input-component :disabled="disabled" v-model:meaning="service.measurement" inputType="text" inputName="measurement" inputContent="п/м" inputLabel="Ед. изм."/>
                            <input-component :disabled="disabled" v-model:meaning="service.cost" @input="service.price =service.cost * service.quantity" inputType="number" inputName="cost" inputContent="1600" inputLabel="Цена за ед., руб."/>
                            <input-component :disabled="disabled" v-model:meaning="service.price" @input="service.cost =+((service.price / service.quantity).toFixed(2))" inputType="number" inputName="price" inputContent="1600" inputLabel="Сумма, руб."/>
                        </div>
                    </div>
                </div>
                <button-component button-text="+ Добавить услугу" class="create-order__button" @click.prevent="addOrderService"/>
            </div>
            <div class="create-order__item additional-service">
                <h3 class="create-order__title_min">Список оказываемых услуг</h3>
                <textarea :disabled="disabled" class="additional-service__field" v-model="updateOrderFields.moreServices" placeholder="Описание дополнительных услуг"></textarea>
            </div>
            <div class="create-order__item payment">
                <div class="col">
                    <div class="cost">
                        <h3 class="create-order__title_min">Расчёт стоимости</h3>
                        <div class="cost__list">
                            <div class="col">
                                <div class="payment__value-box">
                                    <span class="payment__label">Сумма заказа</span>
                                    <div class="payment__value">{{updateOrderFields.price.total}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="discount">
                        <div class="col" v-if="discount">
                            <input-component
                            input-type="number"
                            input-name="discount"
                            input-content="0"
                            input-label="скидка"
                            v-model:meaning=updateOrderFields.price.discountValue
                            >
                                <template #subcontent>
                                    <radio-input
                                    v-model="updateOrderFields.price.discountMeasure"
                                    :options="measure"
                                    />
                                </template>
                            </input-component>
                        </div>
                    </div>
                    <div class="create-order__item pay">
                        <div class="col">
                            <div class="payment__value-box">
                                <span class="payment__label payment__label_big">Со скидкой</span>
                                <div class="payment__value payment__value_gold">{{updateOrderFields.price.final}}</div>
                            </div>
                        </div>
                    </div>
                    <button-component class="create-order__submit" @click.prevent="updateOrder" button-text="Обновить заказ"/>
                </div>
                <div class="col">
                    <h3 class="create-order__title_min">Сроки выполнения работ</h3>
                    <div class="create-order__date">
                        <div class="col">
                            <span class="payment__label">Начало работ <b>*</b></span>
                            <Datepicker :disabled="disabled" class="create-order__date-item" auto-apply format="dd/MM/yyyy" v-model="updateOrderFields.dates.startAt" :enable-time-picker="false" @update:model-value="changeWorkDateStart" locale="ru">
                                <template #calendar-header="{ index, day }">
                                    <div class="date__day" :class="index === 5 || index === 6 ? 'gold-color' : ''">
                                      {{ day }}
                                    </div>
                                  </template>
                            </Datepicker>
                        </div>
                        <div class="col">
                            <span class="payment__label">Окончание работ <b>*</b></span>
                            <Datepicker :disabled="disabled" class="create-order__date-item" auto-apply format="dd/MM/yyyy" v-model="updateOrderFields.dates.endAt" :enable-time-picker="false" @update:model-value="changeWorkDateEnd" locale="ru">
                                <template #calendar-header="{ index, day }">
                                    <div class="date__day" :class="index === 5 || index === 6 ? 'gold-color' : ''">
                                      {{ day }}
                                    </div>
                                  </template>
                            </Datepicker>
                        </div>
                    </div>
                    <div class="create-order__item create-order__comment">
                        <div class="create-order__item additional-service">
                            <h3 class="create-order__title_min">Комментарий к заказу</h3>
                            <textarea :disabled="disabled" class="additional-service__field" v-model="updateOrderFields.comment" placeholder="Текст комментария"></textarea>
                        </div>
                        <!-- <input type="file" class="create-order__button"> -->
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script setup lang="ts">
import InputComponent from '../shared/Form/Input/InputComponent.vue'
import buttonComponent from '../shared/buttonComponent.vue'
import { RadioInput } from '../shared/Form/Radio'
import listService from '../app/lib/json/serviceList.json'
import toast from "../app/lib/hooks/errorToast"
import axios from '../app/lib/api'
import { OrderService, PaymentMeasure, PrepaymentType, PaymentMethod, Order } from '../app/lib/types/orders'
import { computed, onMounted, PropType, ref, watch, watchEffect } from 'vue'
import { updateOrderShema } from '../app/lib/shemas/updateOrderShema'
import { useRoute } from 'vue-router'
import {useLoadingStore} from '../app/store/store'
import { Cities } from '../app/lib/types/cities'

const cityData = ref({} as Cities)

const emit = defineEmits({
    updateOrder: null
})

const props = defineProps({
    order: {
        type: Object as PropType<Order>,
        required: true
    }
})

const loading = useLoadingStore()
const updateOrderFields = ref({
    information: {
        client: "",
        clientEmail: "",
        clientPhone: "",
        clientAddress: "",
        deceased: "" ,
        deathDate: new Date() as Date | undefined,
        cemetery: "" ,
        graveDistrict: "" ,
        graveRow: "" ,
        gravePlace: "" ,
    },
    services: [] as OrderService[],
    moreServices: "",
    price: {
        total: 0,
        discountValue: 0,
        discountMeasure: PaymentMeasure.percent,
        discount: 0,
        final: 0,
    },
    dates: {
      startAt: new Date(),
      endAt: new Date(),
    },
    comment: "" as string | undefined,
    uploadImage: "" as string | undefined,
    signImage: "" ,
    costs: {
        concrete: 0,
        concreteValue: 0,
        concreteMeasure: '',
        materials: 0,
        materialsValue: 0,
        materialsMeasure: '',
        departure: 0,
        departureValue: 0,
        departureMeasure: '',
        brigade: 0,
        brigadeValue: 0,
        brigadeMeasure: '',
        raw: 0,
        rawValue: 0,
        rawMeasure: '',
        install: 0,
        installValue: 0,
        installMeasure: '',
    },
    createdAt: new Date(),
    closedAt: new Date()
})
const errors = ref({
    informationclient: "",
    informationclientPhone: "",
    informationdeceased: "",
    informationcemetery: "",
    informationgraveDistrict: "",
    informationgraveRow: "",
    informationgravePlace: "",
    prepaymentmethod: "",
    datesstartAt: "",
    datesendAt: "",
})
const addServiceList = ref([] as string[])
const discount = ref(true)
const disabled = ref(false)
const measure = Object.values(PaymentMeasure).map((item) => {
    return {
        label: item,
        value: item,
    }
})
const router = useRoute()

onMounted( () => {
    loading.loading()
    axios.cities.getCurrentCity()
    .then((city: any) => {
        loading.load()
        cityData.value = city.data
    })
    .catch((err: any) => {
        loading.load()
        toast('error', err + ' Не удалось получить информацию по городу')
    })
} )

function addOrderService() {
    let service = {
        title: "",
        measurement: "",
        quantity: 0,
        cost: 0,
        price: 0,
    }
    updateOrderFields.value.services.push(service)
}
function removeOrderService(title: string) {
    updateOrderFields.value.services = updateOrderFields.value.services.filter((item) =>  item.title !== title )
}
function setCemetry(item: string) {
    updateOrderFields.value.information.cemetery = item
}
function setMeasurement(service: OrderService) {
    service.measurement = listService.find(item => item.name === service.title)?.measurement || ""
}

/**
 * Обновление данных заказа.
 */
async function updateOrder() {
    try {
        await updateOrderShema.validate(updateOrderFields.value, { abortEarly: false })
        errors.value = {
            informationclient: "",
            informationclientPhone: "",
            informationdeceased: "",
            informationcemetery: "",
            informationgraveDistrict: "",
            informationgraveRow: "",
            informationgravePlace: "",
            prepaymentmethod: "",
            datesstartAt: "",
            datesendAt: "",
        }
        try {
            loading.loading()
            await axios.order.updateOrder(router.params.orderId as string, updateOrderFields.value)
            loading.load()
            toast('success', "Заказ успешно обновлен")
            emit("updateOrder", {
                updateOrderFields: updateOrderFields
            })
        } catch (error: any) {
            loading.load()
            toast('error', error)
        }
    } catch (error: any) {
        console.log(error)
        error.inner.forEach((error: any) => {
            // @ts-ignore
            errors.value[error.path.replace( /\./g, '' )] = error.message;
            if (error.path.includes("service")) {
                toast('error', "выберите услугу")
            }
        });
        if(errors.value.datesstartAt != "") {
            toast('error', errors.value.datesstartAt)
        }
        if(errors.value.datesendAt != "") {
            toast('error', errors.value.datesendAt)
        }
    }
}

/**
 * Обновление даты начала работ.
 */
const changeWorkDateStart = (modelData: Date) => {
    updateOrderFields.value.dates.startAt = modelData
}

/**
 * Обновление даты завершения работ.
 */
const changeWorkDateEnd = (modelData: Date) => {
    updateOrderFields.value.dates.endAt = modelData
}

/**
 * Обновление даты заключения договра.
 */
const changeOrderCreatedAt = (modelData: Date) => {
    updateOrderFields.value.createdAt = modelData
}

/**
 * Обновление даты закрытия договора.
 */
const changeOrderClosedAt = (modelData: Date) => {
    updateOrderFields.value.closedAt = modelData
}

const serviceNameList = computed(() => {
    const usedNames = updateOrderFields.value.services.map(service => service.title)
    return [...listService].filter(service => !usedNames.includes(service.name)).map(service => service.name)

})

const discountPrice = computed(() => updateOrderFields.value.price.final = updateOrderFields.value.price.total - updateOrderFields.value.price.discount)



watch(() => updateOrderFields.value.services, (newVal:typeof updateOrderFields.value.services) => {
    updateOrderFields.value.price.total = newVal.reduce<number>((old, curr) => {
        return old +  +curr.price
    }, 0);
}, {deep: true})

watchEffect(() => {
    if(updateOrderFields.value.price.discountMeasure === PaymentMeasure.percent) {
        updateOrderFields.value.price.discount  = (updateOrderFields.value.price.discountValue * updateOrderFields.value.price.total) / 100
    } else {
        updateOrderFields.value.price.discount  = updateOrderFields.value.price.discountValue
    }
})
watch(() => [updateOrderFields.value.price.discount, updateOrderFields.value.price.total], () => {
    updateOrderFields.value.price.final = updateOrderFields.value.price.total - updateOrderFields.value.price.discount
})

watch(() => [updateOrderFields.value.price.final], ()=> {
    if(updateOrderFields.value.costs.rawMeasure === PaymentMeasure.percent) {
        updateOrderFields.value.costs.raw = ( updateOrderFields.value.costs.rawValue * updateOrderFields.value.price.final) / 100
    }
    if(updateOrderFields.value.costs.materialsMeasure === PaymentMeasure.percent) {
        updateOrderFields.value.costs.materials = ( updateOrderFields.value.costs.materialsValue * updateOrderFields.value.price.final) / 100
    }
    if(updateOrderFields.value.costs.concreteMeasure === PaymentMeasure.percent) {
        updateOrderFields.value.costs.concrete = ( updateOrderFields.value.costs.concreteValue * (updateOrderFields.value.price.final - updateOrderFields.value.costs.raw - updateOrderFields.value.costs.materials)) / 100
    }
    if(updateOrderFields.value.costs.installMeasure === PaymentMeasure.percent) {
        updateOrderFields.value.costs.install = ( updateOrderFields.value.costs.installValue * (updateOrderFields.value.price.final - updateOrderFields.value.costs.raw - updateOrderFields.value.costs.materials)) / 100
    }
    if(updateOrderFields.value.costs.brigadeMeasure === PaymentMeasure.percent) {
        updateOrderFields.value.costs.brigade = ( updateOrderFields.value.costs.brigadeValue * updateOrderFields.value.price.final) / 100
    }
    if(updateOrderFields.value.costs.departureMeasure === PaymentMeasure.percent) {
        updateOrderFields.value.costs.departure = ( updateOrderFields.value.costs.departureValue * updateOrderFields.value.price.final) / 100
    }
})


onMounted(() => {
    //@ts-ignore
    updateOrderFields.value.information = props.order.information
    updateOrderFields.value.services = props.order.services
    updateOrderFields.value.moreServices = props.order.moreServices
    updateOrderFields.value.price = props.order.price
    updateOrderFields.value.dates = props.order.dates
    updateOrderFields.value.comment = props.order.comment
    updateOrderFields.value.uploadImage = props.order.uploadImage
    updateOrderFields.value.costs = props.order.costs
    updateOrderFields.value.dates.startAt = props.order.dates.startAt
    updateOrderFields.value.dates.endAt = props.order.dates.endAt
    updateOrderFields.value.createdAt = props.order.createdAt
    updateOrderFields.value.closedAt = props.order.closedAt
})

</script>

<style lang="sass">
    .create-order
        &__title
            font-family: 'Roboto'
            font-weight: 500
            font-size: 32px
            color: var(--brown)
            &_min
                font-family: 'Roboto'
                font-weight: 500
                font-size: 24px
                color: var(--brown)
        &__required
            display: block
            font-family: 'Roboto'
            font-weight: 300
            font-size: 18px
            color: var(--red)
            margin-top: 10px
        &__item
            margin-top: 60px
        &__button
            display: flex
            gap: 5px
            padding: 12px 28px
            border: 2px solid var(--gold)
            color: var(--gold)
            font-family: 'Roboto'
            font-weight: 500
            font-size: 14px
            margin-top: 30px
        &__submit
            background: var(--gold)
            padding: 14px 30px
            max-width: 386px
            width: 100%
            margin-top: 60px
            font-family: 'Roboto'
            font-weight: 600
            font-size: 18px
            color: var(--white)
        &__remove
            margin-top: 60px
            font-family: 'Roboto'
            font-weight: 300
            font-size: 18px
            color: var(--gray)
            text-decoration: underline
        &__date
            display: grid
            grid-template-columns: repeat(2, calc( 50% - 30px ))
            gap: 30px
            margin-top: 30px
            &-item
                margin-top: 14px
            @media (max-width: 450px)
                grid-template-columns: 100%
    .information
        &__list
            margin-top: 30px
            display: grid
            grid-template-columns: repeat(3, 4fr)
            gap: 30px
            @media (max-width: 1200px)
                grid-template-columns: repeat(2, 6fr)
            @media (max-width: 1200px)
                grid-template-columns: 100%
            &_sub
                display: grid
                grid-template-columns: repeat(3, 120px)
                gap: 13px
                @media (max-width: 430px)
                    grid-template-columns: 100%
        &__select
            max-width: 360px
            width: 100%
            padding: 20px
            &-item
                display: block
                background: none
                width: 100%
                border: none
                color: var(--brown)
                font-size: 18px
                &:not(:first-child)
                    margin-top: 20px
        &__date
            width: 100%
            margin-top: 10px
            .dp__input
                padding: 12px 12px
                border: 1px solid var(--dp-background-color)
            .dp__input_icon_pad
                padding-left: 35px
            &-box
                width: 100%
            &-title
                color: var(--brown)
                font-family: "Roboto"
                font-weight: 300
                font-size: 18px
    .service
        &__list
            margin-top: 30px
            display: grid
            grid-template-columns: repeat(3, 4fr)
            gap: 30px
            @media (max-width: 1450px)
                grid-template-columns: repeat(2, 4fr)
                gap: 30px
            @media (max-width: 990px)
                grid-template-columns: 100%
                gap: 30px
        &__item
            width: 100%
            padding: 20px
            border: 2px solid var(--gold)
            .vs__dropdown-toggle
                width: 100%
                height: 48px
                border: none
                background-color: var(--gray-light)
                margin-top: 10px
                padding: 14px
                align-items: center
            .vs__search
                &::placeholder
                    color: var(--gray)
            .vs__actions
                flex: none
            .vs__clear
                display: none
        &__delete
            padding: 5px 10px
            width: 100%
            border: 1px solid var(--gray)
            border-radius: 3px
            background: none
            display: flex
            justify-content: center
            align-items: center
            gap: 10px
            font-family: 'Roboto'
            font-weight: 500
            font-size: 14px
            color: var(--gray)
        &__title
            display: block
            margin-top: 30px
            color: var(--brown)
            font-family: 'Roboto'
            font-weight: 300
            font-size: 18px
        &__index
            display: block
            width: 100%
            margin-top: 10px
            padding: 5px
            text-align: center
            background-color: var(--brown)
            color: var(--white)
            font-family: 'Roboto'
            font-weight: 600
            font-size: 18px
            border-radius: 3px
        &__checklist
            margin-top: 30px
        &__parametrs
            margin-top: 30px
            display: grid
            grid-template-columns: repeat(2, calc( 50% - 15px ))
            gap: 30px
            @media (max-width: 400px)
                grid-template-columns: 100%
    .additional-service
        &__field
            width: 100%
            height: 150px
            margin-top: 20px
            padding: 14px
            display: block
            resize: none
            background: var(--gray-light)
            border: none
            border-radius: 3px
            color: var(--gray-dark)
            font-family: 'Roboto'
            font-weight: 400
            font-size: 18px
            &::placeholder
                color: var(--gray)
    .payment
        display: grid
        grid-template-columns: repeat(2, 6fr)
        gap: 30px
        @media (max-width: 1230px)
            display: flex
            flex-direction: column-reverse
            width: 100%
        &__label
            display: block
            font-family: 'Roboto'
            font-weight: 300
            font-size: 18px
            color: var(--brown)
            b
                color: var(--red)
            &_big
                font-family: 'Roboto'
                font-weight: 500
                font-size: 24px
                color: var(--brown)
        &__value
            margin-top: 15px
            font-family: 'Roboto'
            font-weight: 500
            font-size: 24px
            color: var(--brown)
            &_gold
                font-size: 30px
                color: var(--gold)
            &_flex
                display: flex
                gap: 16px
                align-items: start
            &_mt
                margin-top: 30px
    .cost
        &__list
            margin-top: 30px
            display: grid
            grid-template-columns: repeat(2, 200px)
            gap: 30px
            @media (max-width: 450px)
                grid-template-columns: 100%
    .radio
        &__list
            margin-top: 14px
            flex: none
        &__item
            display: flex
            align-items: center
            font-family: 'Roboto'
            font-weight: 400
            font-size: 18px
            color: var(--gray-dark)
            input
                width: 20px
                height: 20px
                margin-right: 8px
            &:not(:first-child)
                margin-top: 14px
    .discount
        margin-top: 30px
        display: grid
        grid-template-columns: repeat(2, 178px)
        gap: 30px
        @media (max-width: 450px)
                grid-template-columns: 100%
    .pay
        margin-top: 30px
        display: grid
        grid-template-columns: repeat(2, 200px)
        gap: 30px
        @media (max-width: 450px)
                grid-template-columns: 100%
    .v-popper__arrow-container
        display: none
    .signature
        &-box
            border: 1px solid var(--gray)
            border-radius: 5px
            overflow: hidden
            position: relative
        &__undo
            height: 20px
            &-box
                position: absolute
                top: 5px
                right:5px
                z-index: 2
                border: none
                background: none
        &__button
            text-transform: uppercase
            color: var(--white)
            background: var(--gold)
            width: 100%
            padding: 13px 10px
            &_gray
                background: none
                color: var(--gray)
                border: 1px solid var(--gray)
                margin-top: 20px
            &-box
                width: 100%
                margin-top: 20px
</style>