import { PaymentMeasure, OrderCosts } from "../../../../app/lib/types/orders";

type CalculateOption<T extends Record<string, unknown>> = {
    meaningValue: number
    meaning: number
    option: PaymentMeasure | string
    final: number
} & T

export type CalculateMethod<T extends Record<string, unknown> = {}> = (options:CalculateOption<T>) => number

const easy:CalculateMethod = ({meaningValue, meaning, option, final}) => {
    if(option === PaymentMeasure.percent) {
        return meaning = ( meaningValue * final) / 100
    } else {
        return meaning = meaningValue
    }
}

const hard:CalculateMethod<{
    costs: OrderCosts
}> = ({meaningValue, meaning, option, final, costs}) => {
    if(option === PaymentMeasure.percent) {
        return meaning = ( meaningValue * (final - costs.raw - costs.materials)) / 100
    } else {
        return meaning = meaningValue
    }
}

export class Calculate {
    static easy = easy
    static hard = hard
}