<template>
  <div class="authorization-page">
    <RouterView></RouterView>
  </div>
</template>
<script setup lang="ts">
</script>
<style lang="sass">
.authorization
  &-page
    padding: 100px 0
</style>